import {
  Card,
  successNotify,
  Dropzone,
  Loader,
  Typography,
} from "@/shared/ui-kit";

import React, { useEffect, useMemo, useState } from "react";
import { PhotosScrollContainer } from "./photosScrollContainer";
import { InfoEditor } from "./InfoEditor";
import {
  PHOTO_ACCEPTED_FORMATS,
  MEDIA_MAX_SIZE,
  VIDEO_ACCEPTED_FORMATS,
} from "@/shared/constants/uploadedPhotoParams";
import { ChevronIcon } from "@/shared/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteFileMutation,
  useGetEditableFileSetInfoQuery,
  useGetFileSetQuery,
  useUpdateFileSetMutation,
} from "@/entities/assets/api/apiAssetsSlice";
import { Assets, IFileResponse } from "@/entities/assets/types";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import { deleteKeyByValue } from "@/shared/utils/deleteKeyByValue";
import { SelectedPhotoBlock } from "./ui/SelectedPhotoBlock";

type Props = {
  errorFiles: any;
  localLoadingPhotosCount: number;
  setErrorFiles: React.Dispatch<object>;
  photoIdOrder: string[];
  localReloadingPhotos: string[];
  setPhotoIdOrder: React.Dispatch<React.SetStateAction<string[]>>;
  addPhotosToSet: ({
    files,
    set_id,
    errorFileId,
  }: {
    files: File[];
    set_id: string;
    errorFileId?: string;
  }) => Promise<void>;
};
export const EditSet = ({
  errorFiles,
  photoIdOrder,
  localLoadingPhotosCount,
  localReloadingPhotos,
  setPhotoIdOrder,
  addPhotosToSet,
  setErrorFiles,
}: Props) => {
  const [selectedPhoto, setSelectedPhoto] = useState<IFileResponse | null>(
    null,
  );
  const [photos, setPhotos] = useState([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const setId = searchParams.get("set");
  const { data: setInputsData, isLoading: isEditableFileSetLoading } =
    useGetEditableFileSetInfoQuery();

  const { data, isLoading: isFileSetLoading } = useGetFileSetQuery(
    { file_set_id: setId },
    { skip: !setId },
  );
  const [deletePhoto] = useDeleteFileMutation();
  const [updateFileSet] = useUpdateFileSetMutation();

  const defaultSetData = useMemo(() => {
    const obj = {};

    data
      ? Object.keys(data)?.map((item) => {
          if (item !== "id" && item !== "files") obj[item] = data[item];
        })
      : {};

    return obj;
  }, [data]);

  useEffect(() => {
    setErrorFiles({});
  }, [setId]);

  useEffect(() => {
    if (data) {
      setPhotos(data.files);
      setPhotoIdOrder((prev) => [
        ...prev,
        ...data.files
          .filter((item) => !prev.includes(item.id))
          .map((item) => item.id),
      ]);
    }
  }, [data]);

  const updateFileSetInfo = ({
    id,
    data,
  }: {
    id?: string;
    data: Assets.IEditableFileInfo;
  }) => {
    updateFileSet({ file_set_id: setId, ...data })
      .unwrap()
      .then(() => {
        successNotify({
          title: "Успешно изменено",
        });
      })
      .catch((response) => handleServerResponse(response));
  };

  const onDeletePhoto = (photoId: string) => {
    const photoToDelete = photos.find((photo) => photo.id === photoId);

    if (Object.keys(errorFiles).find((item) => item === photoId)) {
      if (photoId) {
        setErrorFiles((prev) => {
          const newObj = { ...prev };
          deleteKeyByValue(newObj, photoId);

          return newObj;
        });
      }
      return;
    }

    deletePhoto({ file_id: photoId })
      .unwrap()
      .catch((response) => handleServerResponse(response));

    if (photoId === photoToDelete?.id) {
      setSelectedPhoto(null);
    }
  };
  const selectPhoto = (photo: IFileResponse) => {
    setSelectedPhoto(photo);
  };
  const goBack = () => {
    navigate(`/account/?tab=editSet`);
  };

  return (
    <Card bodyClasses="p-10">
      <div className="flex flex-col gap-4">
        <div
          className="flex h-10 cursor-pointer items-center gap-2"
          onClick={goBack}
        >
          <ChevronIcon rotate="90" />
          <Typography>Вернуться назад</Typography>
        </div>
        <div className="flex w-full gap-10">
          <div className="w-[40%] min-w-[280px]">
            <Dropzone
              maxSize={MEDIA_MAX_SIZE}
              maxFiles={200}
              placeholder="Добавить файлы"
              selectedFiles={[]}
              onChange={(files) => addPhotosToSet({ files, set_id: setId })}
              acceptedTypes={{
                "image/*": PHOTO_ACCEPTED_FORMATS,
                "video/*": VIDEO_ACCEPTED_FORMATS,
              }}
            />
          </div>
          <div className="flex w-[50%] flex-wrap">
            <div className="flex w-full flex-col gap-6">
              <Typography
                textAlign="center"
                additionalClasses="text-main !text-lg"
              >
                Edit set info
              </Typography>
              <InfoEditor
                sendData={updateFileSetInfo}
                buttonWidth="max-w-[500px] w-full"
                data={setInputsData}
                isLoading={isEditableFileSetLoading || isFileSetLoading}
                // @ts-ignore
                defaultData={defaultSetData}
              />
            </div>
          </div>
        </div>
        {isFileSetLoading ? (
          <Loader />
        ) : (
          <PhotosScrollContainer
            photoIdOrder={photoIdOrder}
            localReloadingPhotos={localReloadingPhotos}
            addPhotosToSet={addPhotosToSet}
            errorFiles={errorFiles}
            photos={photos}
            localLoadingPhotosCount={localLoadingPhotosCount}
            deletePhoto={onDeletePhoto}
            selectPhoto={selectPhoto}
          />
        )}

        {selectedPhoto && (
          <SelectedPhotoBlock
            selectedPhoto={selectedPhoto}
            deletePhoto={onDeletePhoto}
          />
        )}
      </div>
    </Card>
  );
};
