import React from "react";
import { IFileResponse } from "@/entities/assets/types";
import { PhotoScrollItem } from "./photoScrollItem";
import { PhotoLoading } from "./ui/photoLoading";
import { DeleteIcon, RetryIcon } from "@/shared/icons/16px";
import { useSearchParams } from "react-router-dom";

type Props = {
  errorFiles: any;
  photos: IFileResponse[];
  localLoadingPhotosCount: number;
  photoIdOrder: string[];
  localReloadingPhotos: string[];
  deletePhoto: (photoId: string) => void;
  selectPhoto: (photo: IFileResponse) => void;
  addPhotosToSet: ({
    files,
    set_id,
    errorFileId,
  }: {
    files: File[];
    set_id: string;
    errorFileId?: string;
  }) => Promise<void>;
};
export const PhotosScrollContainer = ({
  photos,
  errorFiles,
  photoIdOrder,
  localReloadingPhotos,
  localLoadingPhotosCount,
  deletePhoto,
  selectPhoto,
  addPhotosToSet,
}: Props) => {
  const [searchParams] = useSearchParams();
  const setId = searchParams.get("set");

  const onRetryClick = (field: string) => {
    addPhotosToSet({
      files: [errorFiles[field]],
      set_id: setId,
      errorFileId: field,
    });
  };
  return (
    <div className="mx-auto flex flex-wrap w-full gap-2">
      {photoIdOrder.map((photoId, index) => {
        const loading = localReloadingPhotos.includes(photoId);
        const error =
          errorFiles && Object.keys(errorFiles).find((id) => id === photoId);
        const photo = photos.find((item) => item.id === photoId);
        if (loading) {
          return (
            <div key={index} className="w-[130px] min-w-[130px]">
              <PhotoLoading />
            </div>
          );
        }
        if (error)
          return (
            <div
              key={index}
              className="relative flex h-full w-[130px] min-w-[130px] flex-col items-center justify-center gap-2 text-red-500"
            >
              Ошибка
              <div onClick={() => onRetryClick(error)}>
                <RetryIcon
                  rotate="90"
                  pointer
                  className="!stroke-stroke-error"
                />
              </div>
              <div
                className="absolute right-0 top-0 h-4 w-4"
                onClick={() => deletePhoto(error)}
              >
                <DeleteIcon />
              </div>
            </div>
          );
        if (photo) {
          return (
            <PhotoScrollItem
              key={index}
              deletePhoto={deletePhoto}
              selectPhoto={selectPhoto}
              photo={photo}
            />
          );
        }
      })}
      {Array.from({ length: localLoadingPhotosCount }).map((_, id) => (
        <div key={id} className="w-[130px] ">
          <PhotoLoading />
        </div>
      ))}
    </div>
  );
};
