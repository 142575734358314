import clsx from "clsx";
import React, { FC, PropsWithChildren, useState } from "react";

import { TIconProps } from "./type";
import { iconStrokeClass, rotateClasses } from "./config";

export const IconContainer: FC<PropsWithChildren<TIconProps>> = ({
  children,
  size = "24",
  hover,
  active,
  rotate,
  noHover,
  pointer = true,
  fill = "none",
  disabled = false,
  onMouseEnter,
  onMouseLeave,
  onClick,
  className,
}) => {
  const [isHover, setHover] = useState(false);

  const cursorClass = clsx("cursor-default", {
    "cursor-pointer": pointer,
    "!cursor-not-allowed": disabled,
  });
  const rotateClass = `${rotateClasses(rotate)} ${
    rotate ? "duration-300" : ""
  }`;
  const defaultClasses = `${iconStrokeClass({
    hover: (hover || isHover) && !noHover,
    active,
    disabled,
  })} ${rotateClass} ${cursorClass}`;

  const iconClasses = className
    ? `${defaultClasses} ${className}`
    : defaultClasses;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill={fill}
      onClick={onClick}
      onMouseEnter={() => {
        onMouseEnter && onMouseEnter();
        setHover(true);
      }}
      onMouseLeave={() => {
        onMouseLeave && onMouseLeave();
        setHover(false);
      }}
      className={iconClasses}
    >
      {children}
    </svg>
  );
};
