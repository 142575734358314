export enum AccountTab {
  INFO = "personalInfo",
  PASSWORD = "resetPassword",
  PHOTOS = "editSet",
  INVITE_USER = "inviteUser",
}
export type TAccountTabs =
  | AccountTab.INFO
  | AccountTab.PASSWORD
  | AccountTab.PHOTOS
  | AccountTab.INVITE_USER;
