import React, { FC, PropsWithChildren } from "react";
import { NothingFoundPage } from "../NothingFound";

import { Skeleton } from "../Skeleton";

type Props = {
  loading: boolean;
  notFound: boolean;
};
export const GalleryContainer: FC<PropsWithChildren<Props>> = ({
  children,
  loading,
  notFound,
}) => {
  if (loading) {
    return (
      <div className="flex flex-wrap justify-center gap-5">
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} />
        ))}
      </div>
    );
  } else if (notFound) {
    return <NothingFoundPage />;
  } else {
    return (
      <div className="flex w-full flex-wrap justify-center gap-5">
        {children}
      </div>
    );
  }
};
