import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

const errorHandlingMiddleware: Middleware =
  (api) => (next) => async (action) => {
    if (isRejectedWithValue(action)) {
      const payload = action.payload as { status?: number };
    }
    return next(action);
  };
export default errorHandlingMiddleware;
