import { AccountTabs } from "@/widgets/AccountTabs";
import {
  ResetPasswordTab,
  PersonalInfoTab,
  SetsEditionTab,
  InviteUserTab,
} from "@/widgets/AccountTabs/tabs";
import { AccountTab } from "@/shared/types/AccountTabs";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Account = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("tab");
  const isTabExist = Boolean(activeTab);

  useEffect(() => {
    if (!isTabExist) {
      setSearchParams({ tab: AccountTab.INFO });
    }
  }, [isTabExist]);

  return (
    <div className={clsx("mx-auto w-full max-w-full pt-[30px] ")}>
      <div className=" w-full ">
        <div className="flex w-full gap-10 ">
          <AccountTabs />
          <div className="flex w-[70%] pr-8">
            {activeTab === AccountTab.INFO && <PersonalInfoTab />}
            {activeTab === AccountTab.PASSWORD && <ResetPasswordTab />}
            {activeTab === AccountTab.PHOTOS && <SetsEditionTab />}
            {activeTab === AccountTab.INVITE_USER && <InviteUserTab />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
