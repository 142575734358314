import React, { ChangeEventHandler, useState } from "react";
import { Typography } from "../../../../shared/ui-kit/Typography";
import { Input } from "../../../../shared/ui-kit/Input";
import { TFilter, TFilterChange } from "../types";

type Props = {
  filterData: TFilter;
  onFilterChange: (props: TFilterChange) => void;
};
export const RangeFilter = ({ filterData, onFilterChange }: Props) => {
  const [fromFilter, setFromFilter] = useState(null);
  const [toFilter, setToFilter] = useState(null);

  const onChangeFromFilter: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromFilter(e?.target?.value);
  };
  const onChangeToFilter: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToFilter(e?.target?.value);
  };

  const onFromBlur = () => {
    onFilterChange({
      field: filterData.field,
      type: "range",
      value: [fromFilter, toFilter],
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <Typography pattern="headline1">{filterData.title}</Typography>
      <div className="flex w-full gap-2">
        <Input
          maxLength={30}
          size="sm"
          height="h-3"
          placeholder="от"
          value={fromFilter}
          onChange={onChangeFromFilter}
          onBlur={onFromBlur}
        />
        <Input
          size="sm"
          height="h-4"
          maxLength={30}
          placeholder="до"
          value={toFilter}
          onBlur={onFromBlur}
          onChange={onChangeToFilter}
        />
      </div>
    </div>
  );
};
