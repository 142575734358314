import { IFileResponse } from "@/entities/assets/types";
import React from "react";
import _ from "lodash";
import { Accordion, Loader, Typography } from "@/shared/ui-kit";
import { stringifyFileSize } from "@/shared/utils/stringifyFileSize";
import { transformDate } from "@/shared/utils/transformDate";
import { transformObjectKey } from "@/shared/utils/transformObjectKey";
import langId from "@/entities/language";

type Props = {
  data: IFileResponse;
};
export const PhotoMeta = ({ data }: Props) => {
  const dateFields = ["shoot_dt", "upload_dt"];
  const sizeFields = ["size"];
  const closeFields = ["meta"];

  const excludeFields = ["objs", "file_set_id", "file_type",
      "location_country_code", "draft", "status", "location_state", "location_city", "location_description",
      "dpi", "y_resolution", ...closeFields];

  const returnList = (source) => {
    const keys = Object.keys(source);
    return keys
      ?.filter((key) => !excludeFields.includes(key))
      .map((key, index) => {
        let value = source[key] || "unknown";
        if (dateFields.includes(key)) {
          value = transformDate(value).toString();
        }
        if (sizeFields.includes(key)) {
          value = stringifyFileSize({ bytes: +value }).toString();
        }
        let title = transformObjectKey(key);

        if (title == 'X resolution') {
            title = 'Resolution'
            value = `${value}x${source['y_resolution']}`
        } else if (title == 'Location country name') {
            title = 'Location'
            value = `${value}, ${source['location_state']}, ${source['location_city']}, ${source['location_description']}`
        } else if (title == 'Description') {
            value = value?.split('//')[langId]
        }


        return (
          <li className="flex gap-3" key={index}>
            <Typography display="inline">
                {`${title}: `}
                <Typography display="inline">{value.toString()}</Typography>
            </Typography>
          </li>
        );
      });
  };
  return (
    <ul className="flex h-full w-full flex-col">
      {data ? (
        <div className="flex flex-col gap-4">
            <div>{returnList(data)}</div>
          <Accordion header="Meta">{returnList(data.meta)}</Accordion>
        </div>
      ) : (
        <Loader />
      )}
    </ul>
  );
};
