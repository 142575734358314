import React from "react";
import { cardSizes } from "@/shared/constants/cardsSizes";
import { Card, Skeleton as NextSkeleton } from "@nextui-org/react";

type TPattern = "square" | "card";
type Props = {
  pattern?: TPattern;
};

export const Skeleton = ({ pattern = "card" }: Props) => {
  const cardWidth = cardSizes.containerWidth;
  const cardHeight = cardSizes.containerHeight;

  const imgWidth = cardSizes.imageWidth;
  const imgHeight = cardSizes.imageHeight;
  if (pattern === "card")
    return (
      <Card className={`${cardWidth} ${cardHeight} space-y-5 rounded-none p-4`}>
        <NextSkeleton className="rounded-none">
          <div className={`bg-default-300 rounded-lg ${imgHeight}`}></div>
        </NextSkeleton>
        <div className="space-y-3">
          <NextSkeleton className="w-3/5 rounded-lg">
            <div className="bg-default-200 h-3 w-3/5 rounded-lg"></div>
          </NextSkeleton>
          <NextSkeleton className="w-4/5 rounded-lg">
            <div className="bg-default-200 h-3 w-4/5 rounded-lg"></div>
          </NextSkeleton>
        </div>
      </Card>
    );
  if (pattern === "square")
    return (
      <NextSkeleton className="rounded-none">
        <div className={`bg-default-300 rounded-lg ${imgHeight}`}></div>
      </NextSkeleton>
    );

  return <></>;
};
