import { AccountTab, TAccountTabs } from "@/shared/types/AccountTabs";
import { Button, Card } from "@/shared/ui-kit";
import React from "react";
import { useSearchParams } from "react-router-dom";

export const AccountTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab");
  const accountList = [
    {
      title: "Смена имени",
      path: AccountTab.INFO,
    },
    {
      title: "Смена пароля",
      path: AccountTab.PASSWORD,
    },
    {
      title: "Редактор сетов",
      path: AccountTab.PHOTOS,
    },
    {
      title: "Пригласить пользователя",
      path: AccountTab.INVITE_USER,
    },
  ];

  const goOnTab = (tab: TAccountTabs) => {
    setSearchParams({ tab });
  };
  return (
    <div className="w-[324px]">
      <div className="flex flex-col gap-6 px-8 pb-7">
        {accountList.map((item, index) => {
          const isActive = activeTab === item.path;
          const pattern = isActive ? "primary" : "secondary";
          return (
            <Button
              onClick={() => goOnTab(item.path)}
              key={index}
              pattern={pattern}
              additionalClasses="justify-start "
            >
              {item.title}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
