import { emailRegExp } from "@/shared/constants/regExpressions";
import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Введите email")
    .matches(emailRegExp, "Введите корректный email"),
  password: yup.string().required("Введите пароль"),
  totp: yup.string().required("Введите totp"),
});
