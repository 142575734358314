import "react-toastify/dist/ReactToastify.css";

import { toast, ToastContainer } from "react-toastify";
import React from "react";

import { Typography } from "..";

const TOASTERS_LIMIT = 3;
const AUTOCLOSE_DELAY = 3000;

const Notify = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={AUTOCLOSE_DELAY}
      limit={TOASTERS_LIMIT}
      closeOnClick
      theme="dark"
    />
  );
};

const NotifyComponent = ({
  title,
  subtitle,
  type,
}: {
  title: string;
  subtitle?: string;
  type: "error" | "success";
}) => {
  return (
    <div className="flex items-start gap-3">
      <div className="flex flex-col gap-2">
        <Typography color="#fff">{title}</Typography>
        {subtitle ? <Typography color="#fff">{subtitle}</Typography> : null}
      </div>
    </div>
  );
};

const successNotify = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => {
  toast.success(
    <NotifyComponent title={title} subtitle={subtitle} type="success" />,
    {
      icon: false,
    },
  );
};

const errorNotify = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => {
  toast.error(
    <NotifyComponent title={title} subtitle={subtitle} type="error" />,
    {
      icon: false,
    },
  );
};

export { errorNotify, Notify, successNotify };
