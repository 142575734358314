import React from "react";
import { TIconProps } from "./ui/type";
import { IconContainer } from "./ui/iconContainer";

export const EyeCloseIcon = (props: TIconProps) => (
  <IconContainer size="16" {...props}>
    <path
      d="M13.3333 9.88901C14.2055 8.88781 14.6667 8.00001 14.6667 8.00001C14.6667 8.00001 12.2424 3.33334 8.00001 3.33334C7.77254 3.33334 7.55027 3.34676 7.33334 3.37216C7.10521 3.39886 6.88294 3.43882 6.66667 3.49036"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6C8.23373 6 8.45813 6.0401 8.66667 6.11381C9.23493 6.31466 9.68533 6.76507 9.8862 7.33333C9.95987 7.54187 10 7.76627 10 8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 2L14 14"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10C7.76627 10 7.54187 9.95988 7.33334 9.88621C6.76507 9.68534 6.31466 9.23494 6.1138 8.66668C6.07591 8.55948 6.0469 8.44808 6.02765 8.33334"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.76467 6C2.55917 6.22967 2.37489 6.45494 2.21242 6.66667C1.63523 7.4188 1.33333 8 1.33333 8C1.33333 8 3.75757 12.6667 8 12.6667C8.22746 12.6667 8.44973 12.6533 8.66666 12.6279"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconContainer>
);
