import { createApi } from "@reduxjs/toolkit/query/react";

import { BACKEND_ENDPOINTS } from "src/shared/endpoints";
import { baseQueryWithReauth } from "src/shared/redux/services/apiSlice";
import { Users } from "../types";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    //queries
    me: builder.query<Users.IUser, Users.IGetMeRequest>({
      query: ({ user_id }) => ({
        url: BACKEND_ENDPOINTS.me(user_id),
        method: "GET",
      }),
    }),

    // mutations
    refreshToken: builder.mutation<
      Users.IRefreshResponse[],
      Users.IRefreshRequest
    >({
      query: ({ refresh_token }) => ({
        url: BACKEND_ENDPOINTS.refresh(),
        method: "POST",
        body: refresh_token,
      }),
    }),
    login: builder.mutation<Users.ILoginResponse, Users.ILogin>({
      query: (data) => ({
        url: BACKEND_ENDPOINTS.login(),
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation<Users.ISuccess, void>({
      query: (data) => ({
        url: BACKEND_ENDPOINTS.logout(),
        method: "DELETE",
        body: data,
      }),
    }),
    signup: builder.mutation<Users.ISignUpResponse, Users.ISignUp>({
      query: (data) => ({
        url: BACKEND_ENDPOINTS.signup(),
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation<Users.ISuccess, Users.IResetPassword>({
      query: ({ user_id, ...data }) => ({
        url: BACKEND_ENDPOINTS.resetPassword(user_id),
        method: "POST",
        body: data,
      }),
    }),
    inviteUser: builder.mutation<Users.IInviteResponse, Users.ISendInvite>({
      query: (data) => ({
        url: BACKEND_ENDPOINTS.invite(),
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  //queries
  useMeQuery,

  // mutations
  useRefreshTokenMutation,
  useLoginMutation,
  useLogoutMutation,
  useSignupMutation,
  useResetPasswordMutation,
  useInviteUserMutation,
} = usersApi;
