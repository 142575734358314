import React, { useState } from "react";

import { Typography } from "../../../../shared/ui-kit/Typography";
import { Radio } from "../../../../shared/ui-kit/Radio";
import { TSearchFilterField } from "@/shared/types/SearchFilterField";
import { TFilter, TFilterChange, TSelection } from "../types";

type Props = {
  filterData: TFilter;
  onFilterChange: (props: TFilterChange) => void;
};
export const RadioGroup = ({ filterData, onFilterChange }: Props) => {
  const [value, setValue] = useState("");

  const handleChange = (filterItem: TSelection) => {
    const key = filterData.field;
    const newValue = filterItem.id === value ? undefined : filterItem.id;

    setValue(newValue);

    onFilterChange({ field: key, type: "radiobutton", value: newValue });
  };

  return (
    <div className="flex w-full flex-col gap-2 p-0">
      <Typography pattern="headline1">{filterData.title}</Typography>
      {filterData.selections.map((filterItem) => (
        <div
          className="flex cursor-pointer items-center gap-2"
          key={filterItem.id}
          onClick={() => handleChange(filterItem)}
        >
          <Radio selected={value === filterItem.id} />
          <Typography> {filterItem.label}</Typography>
        </div>
      ))}
    </div>
  );
};
