import {
  useLogoutMutation,
  useMeQuery,
} from "@/entities/users/api/apiUsersSlice";
import { useAppDispatch } from "@/shared/redux/hooks";
import { logout as logoutDispatch } from "@/shared/redux/services/authSlice";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ProfilePopover = () => {
  const { data: userData } = useMeQuery({ user_id: "current" });
  const [logout] = useLogoutMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goSettings = () => {
    navigate("/account");
  };
  const goLogout = () => {
    logout()
      .unwrap()
      .then(() => {
        dispatch(logoutDispatch());
        navigate("/");
      })
      .catch((response) => handleServerResponse(response));
  };
  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <Avatar
          isBordered
          as="button"
          className="transition-transform"
          color="secondary"
          name={userData?.full_name || ""}
          size="sm"
          src=""
        />
      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownItem
          key="profile"
          className="h-14 cursor-default gap-2"
          isReadOnly
        >
          <p className="font-semibold">{userData?.email}</p>
        </DropdownItem>
        <DropdownItem onClick={goSettings} key="settings">
          Настройки
        </DropdownItem>
        <DropdownItem onClick={goLogout} key="logout" color="danger">
          Выйти
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
