import React, { FC, PropsWithChildren } from "react";
import { Skeleton } from "../Skeleton";
import { cardSizes } from "@/shared/constants/cardsSizes";

type Props = {
  onClick?: () => void;
  imageUrl: string;
};
export const PhotoCard: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  imageUrl,
}) => {
  const cardWidth = cardSizes.containerWidth;
  const cardHeight = cardSizes.containerHeight;
  return (
    <div
      className={`bg-bg-lilaclight z-0 flex cursor-pointer flex-col gap-2.5 p-2.5 ${cardHeight} ${cardWidth}`}
      onClick={onClick}
    >
      {imageUrl ? (
        <img
          className="h-[221px] overflow-hidden object-cover"
          src={imageUrl}
          alt="img"
        />
      ) : (
        <Skeleton pattern="square" />
      )}
      {children}
    </div>
  );
};
