import React from "react";

import { SearchLine } from "@/features/searchLine/SearchLine";
import { Gallery } from "@/widgets/Gallery/Gallery";
import clsx from "clsx";
import { CONTAINER_WIDTH } from "@/shared/constants/elementsWidth";

export function MainPage() {
  return (
    <div className="w-full pb-[50px]">
      <div className="absolute top-0 h-[600px] w-full bg-mainBg" />
      <div className="relative flex h-[318px] flex-col items-center justify-center">
        <div className="text-[80px] font-extrabold text-white	">
          photo bank
        </div>
        <div className="text-[32px] font-light text-white">
          Ничего не найдётся, но вы держитесь
        </div>
      </div>
      <SearchLine />
      <div className={clsx("mx-auto mt-[200px]", CONTAINER_WIDTH)}>
        <Gallery />
      </div>
    </div>
  );
}
