import { Card } from "@/shared/ui-kit";
import { useResetPasswordMutation } from "@/entities/users/api/apiUsersSlice";
import {
  ResetPasswordForm,
  TResetPassword,
} from "@/features/personalAccountForms/ResetPasswordForm";
import React from "react";

export const ResetPasswordTab = () => {
  const user_id = "current"; //TODO: add user_id

  const [reset, isLoading] = useResetPasswordMutation();

  const onSubmit = ({ old_password, new_password }: TResetPassword) => {
    reset({ user_id, old_password, new_password });
  };
  return (
    <Card bodyClasses="flex flex-col max-w-[500px] p-10 w-full ">
      <ResetPasswordForm onSubmit={onSubmit} />
    </Card>
  );
};
