import { Button, Input, QRCode, Typography } from "@/shared/ui-kit";
import React, { Dispatch, SetStateAction, useState } from "react";

type Props = {
  QRCodeValue: string;
  getTotp: (value: string) => void;
};
export const QRCodeForm = ({ QRCodeValue, getTotp }: Props) => {
  const [value, setValue] = useState("");
  const [isInputError, setIsInputError] = useState(false);

  const onChange = (value: string) => {
    setValue(value);
    setIsInputError(false);
  };

  const onClick = () => {
    if (value) {
      getTotp(value);
    } else {
      setIsInputError(true);
    }
  };
  return (
    <div className="mx-auto flex w-[220px] flex-col gap-8 pb-4">
      <Typography pattern="body1" textAlign="center">
        Отсканируйте QR код
      </Typography>
      <QRCode value={QRCodeValue} />
      <Typography pattern="body1" textAlign="center">
        И введите полученный totp
      </Typography>
      <Input
        size="sm"
        value={value}
        onChange={(e) => onChange(e?.target?.value)}
        placeholder="totp"
        errorMessage={isInputError ? "Введите totp" : undefined}
      />
      <Button onClick={onClick}>Войти</Button>
    </div>
  );
};
