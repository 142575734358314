import clsx from "clsx";
import { isEmpty, isEqual } from "lodash";
import React, { Key, ReactNode, useEffect, useMemo, useState } from "react";

import { Typography } from "..";
import { DropdownView, TDropdownViewProps } from "./ui/DropdownView";

export type TDropdownList = {
  key: string;
  label: string;
  icon?: ReactNode;
}[];

export type TDropdownProps = {
  list: TDropdownList;
  value?: string;
  onChange?: (keys: string[]) => void;
  onSelect?: () => void;
  onStringChange?: (value: string) => void;
  classNames?: string;
  errorMessage?: string;
  onSelectClick?: (e: Set<React.Key>) => void;
  onCleanClick?: () => void;
  selectedValue?: string;
  defaultSelectIds?: string[];
  selectedKeys?: Set<Key>;
} & TDropdownViewProps;

export const Dropdown = ({
  list,
  errorMessage,
  classNames = "",
  onChange,
  onSelect,
  onStringChange,
  onCleanClick: defaultOnCleanClick,
  onSelectClick: defaultOnSelectClick,
  selectedValue: defaultSelectedValue,
  selectedKeys: defaultSelectedKeys,
  //only to controll without query dependance
  defaultSelectIds,
  ...props
}: TDropdownProps) => {
  const [selectedKeys, setSelectedKeys] = useState<
    Set<Key> | undefined | "all"
  >(new Set([]));

  const onCleanClick = () => {
    if (defaultOnCleanClick) {
      defaultOnCleanClick();
    } else {
      setSelectedKeys(new Set([]));
    }
  };

  const selectedValue = useMemo(() => {
    if (selectedKeys && selectedKeys !== "all") {
      const selected = selectedKeys?.size || 0;

      const firstItem =
        selectedKeys && list.find((item) => selectedKeys.has(item.key));

      const firstName = firstItem?.label;
      const firstId = firstItem?.key;
      const name =
        selected === 0
          ? ""
          : selected === 1
            ? firstName
            : `Выбрано: ${selected}`;
      onStringChange && onStringChange(firstId || "");
      return name;
    }
    if (list.length === 0) {
      return "";
    }
  }, [selectedKeys, list]);

  const onSelectClick = (e: Set<React.Key>) => {
    if (defaultOnSelectClick) {
      defaultOnSelectClick(e);
    } else {
      onSelect && onSelect();
      const arraySelectedValues = Array.from(e).map(String);
      if (arraySelectedValues && onChange) {
        onChange(arraySelectedValues);
      }

      setSelectedKeys(e);
    }
  };

  useEffect(() => {
    if (!isEmpty(defaultSelectIds)) {
      setSelectedKeys(new Set(defaultSelectIds));
    }
  }, []);

  useEffect(() => {
    if (selectedKeys && selectedKeys !== "all") {
      const arraySelectedValues = Array.from(selectedKeys).map(String);

      const areArraysEqual =
        defaultSelectIds && isEqual(defaultSelectIds, arraySelectedValues);
      if (!areArraysEqual && defaultSelectIds) {
        onChange && onChange(arraySelectedValues);
      }
    }
    if (!defaultSelectIds) {
      setSelectedKeys(undefined);
    }
  }, [defaultSelectIds]);

  return (
    <div className={clsx("flex flex-col gap-2", classNames)}>
      <DropdownView
        list={list}
        onClean={onCleanClick}
        onSelect={onSelectClick}
        selectedKeys={defaultSelectedKeys || selectedKeys}
        selectedValue={defaultSelectedValue || selectedValue}
        isError={!!errorMessage}
        {...props}
      />
      {errorMessage && (
        <Typography className="text-text-dark before:content-errorIcon flex items-center gap-2 text-xs before:inline-block before:h-4 before:w-4">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
