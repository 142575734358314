import React from "react";

import { PolyhexLogo } from "@/shared/icons";
import { LoginPopup } from "@/features/LoginPopover";
import { Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { errorNotify } from "@/shared/ui-kit";
import { setAuth } from "@/shared/redux/services/authSlice";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import { useLoginMutation } from "@/entities/users/api/apiUsersSlice";
import { Users } from "@/entities/users/types";
import { ProfilePopover } from "@/features/ProfilePopover";

function Header() {
  const { isLoading, isAuthenticated } = useAppSelector((state) => state.auth);

  const [login] = useLoginMutation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const getLoginData = ({ email, password, totp }: Partial<Users.ILogin>) => {
    login({
      email,
      password,
      totp: totp,
    })
      .unwrap()
      .then((response) => {
        localStorage.setItem("refresh_token", response.refresh_token);
        localStorage.setItem("access_token", response.access_token);
        navigate("/account");

        dispatch(setAuth());
      })
      .catch((response) => {
        handleServerResponse(response);
        const isNotAuthorized = +response.status === 401;
        if (isNotAuthorized)
          errorNotify({ title: "Неверный логин или пароль" });
      });
  };
  return (
    <Navbar
      classNames={{
        base: "h-[90px] bg-header-transparent !px-[200px] backdrop-blur-none",
      }}
    >
      <NavbarBrand>
        <Link to={"/"}>
          <PolyhexLogo />
        </Link>
      </NavbarBrand>
      <NavbarContent as="div" justify="end" className="hidden gap-4 sm:flex">
        {isAuthenticated ? (
          <ProfilePopover />
        ) : (
          <NavbarItem>
            <LoginPopup />
          </NavbarItem>
        )}
      </NavbarContent>
    </Navbar>
  );
}
export default Header;
