export enum FileTypes {
  MP3 = ".mp3",
  MPEG = ".mpeg",
  WAV = ".wav",
  PNG = ".png",
  JPEG = ".jpeg",
  MP4 = ".mp4",
  PDF = ".pdf",
  XLSX = ".xlsx",
}
export enum FileNames {
  MP3 = "MP3",
  WAV = "WAV",
  PNG = "PNG",
  JPEG = "JPEG",
  MP4 = "MP4",
  PDF = "PDF",
  XLSX = "XLSX",
}
