import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./services/apiSlice";
import authReducer from "./services/authSlice";
import { assetsApi } from "@/entities/assets/api/apiAssetsSlice";
import errorHandlingMiddleware from "@/serverErrorsMiddleware";
import { searchApi } from "@/entities/search/api/apiSearchSlice";
import { usersApi } from "@/entities/users/api/apiUsersSlice";

export const makeStore = () => {
  return configureStore({
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      [assetsApi.reducerPath]: assetsApi.reducer,
      [searchApi.reducerPath]: searchApi.reducer,
      [usersApi.reducerPath]: usersApi.reducer,
      auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(apiSlice.middleware)
        .concat(assetsApi.middleware)
        .concat(searchApi.middleware)
        .concat(usersApi.middleware)
        .concat(errorHandlingMiddleware),
    devTools: process.env.NODE_ENV !== "production",
  });
};
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
