import React from "react";
import { Navigate } from "react-router-dom";

const hasAccess = () => {
  return Boolean(localStorage.getItem("access_token"));
};

const withAccessCheck = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const WrappedComponent = (props: P) => {
    if (!hasAccess()) {
      return <Navigate to="/" replace />;
    }

    return <Component {...props} />;
  };

  WrappedComponent.displayName = `WithAccessCheck(${getDisplayName(Component)})`;

  return WrappedComponent;
};

function getDisplayName<P>(Component: React.ComponentType<P>): string {
  return Component.displayName || Component.name || "Component";
}
export default withAccessCheck;
