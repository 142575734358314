import { IconContainer } from "@/shared/icons/ui/iconContainer";
import clsx from "clsx";
import React from "react";
type Props = {
  selected: boolean;
  hover?: boolean;
  disabled?: boolean;
};

export const Radio = ({ selected, hover, disabled }: Props) => {
  const fillClasses = clsx({
    "fill-button-primary-bg-disabled": disabled,
    "fill-bg-white": !disabled,
  });

  const disabledClasses = "fill-button-primary-bg-disabled";
  const hoverClasses = "stroke-stroke-hover";
  const radioButtonClasses = disabled
    ? disabledClasses
    : hover
      ? hoverClasses
      : "stroke-stroke-default";

  if (!selected) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={radioButtonClasses}
      >
        <circle cx="12" cy="12" r="11.5" className={fillClasses} />
      </svg>
    );
  } else
    return (
      <IconContainer noHover>
        <circle
          cx="12"
          cy="12"
          r="11.5"
          className={clsx(fillClasses, {
            "stroke-button-primary-bg-disabled": disabled,
            "stroke-main": !disabled,
          })}
        />
        <circle
          cx="12"
          cy="12"
          r="6"
          className={clsx({
            "fill-button-primary-text-disabled": disabled,
            "fill-main": !disabled,
          })}
        />
      </IconContainer>
    );
};
