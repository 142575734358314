import { DownloadIcon } from "@/shared/icons/24px";
import clsx from "clsx";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Accept, useDropzone } from "react-dropzone";
import { Typography } from "../Typography";

type Props = {
  maxSize: number;
  acceptedTypes?: Accept;
  selectedFiles: File[];
  onChange?: (files) => void;
  maxFiles: number;
  addId?: boolean;
  placeholder?: string;
  type?: "audio" | "default" | "image";
};
export const Dropzone = ({
  maxSize,
  maxFiles,
  acceptedTypes,
  selectedFiles: files,
  addId = false,
  type = "default",
  placeholder,
  ...props
}: Props) => {
  const [isSizeError, setSizeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTypeError, setTypeError] = useState(false);
  const [maxFilesErrors, setMaxFilesErrors] = useState(false);

  const isFilled = Boolean(files.length > 0);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIsLoading(true);

      const readFiles = acceptedFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (addId) {
              resolve({
                file: file,
                id: crypto.randomUUID(),
                content: reader.result,
              });
            } else {
              resolve(file);
            }
          };

          reader.readAsBinaryString(file);
        });
      });

      Promise.all(readFiles).then((newFiles) => {
        const newCollection = [...newFiles, ...files];
        if (newCollection.length > maxFiles) {
          setMaxFilesErrors(true);
        } else {
          setMaxFilesErrors(false);
          props.onChange && props.onChange(newCollection as any);
        }
        setIsLoading(false);
      });
    },
    [files, maxFiles, props],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    maxSize,
    accept: acceptedTypes,
    validator: fileValidator,
  });

  function fileValidator(file: File) {
    // props.onChange && props.onChange([file]);
    setSizeError(false);
    setTypeError(false);

    const isCorrectSize = maxSize && file.size <= maxSize;

    if (!isCorrectSize) {
      setSizeError(true);
    }

    const allValues = Object.values({ ...acceptedTypes });

    const allAcceptedValues = allValues.flat();
    const currentFileFormat = file.type.split("/");

    const isCorrectType = allAcceptedValues.includes(
      `.${currentFileFormat[1]}`,
    );

    if (!isCorrectType) {
      setTypeError(true);
    }

    return null;
  }
  return (
    <div className="flex w-full max-w-[540px] flex-col gap-2">
      <div
        {...getRootProps()}
        className={clsx(
          "mx-auto flex w-full cursor-pointer items-center gap-4 rounded-lg border border-solid px-5 py-8 shadow-md duration-400 hover:border-main hover:bg-bg-lilaclight",
          {
            "border-main": !isSizeError && !isTypeError && !maxFilesErrors,
            "border-stroke-error": isSizeError || isTypeError || maxFilesErrors,
          },
        )}
      >
        <input {...getInputProps()} />
        {placeholder ? (
          placeholder
        ) : isFilled ? (
          <p>Добавить файлы</p>
        ) : (
          <p>Перетащите файл сюда или кликните чтобы выбрать файл</p>
        )}
        <DownloadIcon hover />
      </div>
      {isTypeError && (
        <Typography color="text-stroke-error">Неверный формат файла</Typography>
      )}
      {isSizeError && (
        <Typography color="text-stroke-error">Неверный размер файла</Typography>
      )}
      {maxFilesErrors && (
        <Typography color="text-stroke-error">
          Максимальное число фотографий - {maxFiles}
        </Typography>
      )}
    </div>
  );
};
