import React, {
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import {
  InputProps as NextInputProps,
  Input as NextInput,
} from "@nextui-org/react";
import clsx from "clsx";

export interface InputProps extends NextInputProps {
  startContent?: ReactNode;
  endContent?: ReactNode;
  numeric?: boolean;
  float?: boolean;
  label?: ReactNode;
  height?: string;
  description?: ReactNode;
  variant?: "flat" | "bordered" | "faded";
  value?: string | (readonly string[] & string);
  isClearable?: boolean;
  errorMessage?: string;
  onBlur?: FocusEventHandler<HTMLInputElement> &
    ((e: FocusEvent<Element, Element>) => void);
  onClear?: () => void;
  onChangeNumeric?: (value: string) => void;
  size?: "sm" | "md" | "lg";
  additionalClasses?: Partial<
    Record<
      | "base"
      | "label"
      | "inputWrapper"
      | "innerWrapper"
      | "mainWrapper"
      | "input"
      | "clearButton"
      | "helperWrapper"
      | "description"
      | "errorMessage",
      string
    >
  >;
}
export const Input: React.FC<InputProps> = ({
  startContent,
  endContent,
  errorMessage,
  disabled,
  onChangeNumeric,
  numeric,
  value,
  isClearable,
  onClear,
  onBlur,
  type,
  label,
  float,
  defaultValue,
  description,
  size,
  height,
  ...props
}) => {
  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (numeric && onChangeNumeric) {
      const sanitizedValue = e.target.value.replace(/\D/g, "");
      if (sanitizedValue !== value) onChangeNumeric(sanitizedValue);
    }
    if (float && onChangeNumeric) {
      const sanitizedValue = e.target.value
        .replace(/[^\d.]/g, "")
        .replace(/(\..*)\./g, "$1");
      if (sanitizedValue !== value) onChangeNumeric(sanitizedValue);
    }
  };

  return (
    <NextInput
      isDisabled={disabled}
      isInvalid={!!errorMessage}
      errorMessage={errorMessage}
      data-warning={true}
      isClearable={isClearable}
      size={size}
      onClear={onClear}
      onBlur={onBlur}
      onChange={onInputChange}
      radius="sm"
      type={type}
      variant="bordered"
      label={label}
      value={value}
      defaultValue={defaultValue as string}
      description={description}
      endContent={endContent}
      startContent={startContent}
      classNames={{
        clearButton: "hide-default-svg bg-clearButton bg-no-repeat",
        base: clsx("text-base !opacity-100"),

        inputWrapper: clsx(
          "px-3 py-1 bg-bg-white shadow-none border-stroke-default border border-solid data-[hover=true]:border-stroke-hover group-data-[focus=true]:border-text-black group-data-[focus=true]:shadow-input group-data-[invalid=true]:shadow-none group-data-[warning=true]:!border-form-border-warning",
          height,
        ),

        innerWrapper: clsx("group-data-[has-label=true]:mt-2 "),

        input: clsx(
          "focus:shadow-none focus:border-none focus:ring-0 p-0 group-data-[disabled=true]:text-text-disabled !text-text-black",
          { "text-base": size === "md" },
        ),

        description: clsx(
          "text-text-dark group-data-[disabled=true]:text-form-text-disabled",
        ),
        helperWrapper: clsx("mt-1"),
        errorMessage: clsx("flex gap-2 text-stroke-error"),
      }}
      {...props}
    />
  );
};
