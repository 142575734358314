import React, { useEffect, useState } from "react";
import { GalleryContainer } from "@/shared/ui-kit";
import langId from "@/entities/language";
import { useSearchParams } from "react-router-dom";
import { flushSync } from "react-dom";
import { transformDate } from "@/shared/utils/transformDate";
import { CardWithPhoto } from "../getPhotoForCard";
import { SearchFilters } from "../SearchFilters";
import { CARD_LOAD_STEP } from "@/shared/constants/cardLoadStep";
import { Search } from "@/entities/search/types";
import { useSearchAlbumMutation } from "@/entities/search/api/apiSearchSlice";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import { findPhotoIdForGallery } from "@/shared/utils/findPhotoIdWithSpecificQuality";
import { isEmpty } from "lodash";

const AlbumsGallery = () => {
  const [search, { data, isLoading }] = useSearchAlbumMutation();
  const [galleryData, setGalleryData] = useState<Search.TSetResponse[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestParams, setRequestParams] =
    useState<Search.ISearchAssetsRequest | null>(null);

  useEffect(() => {
    const searchParam = searchParams.get("search") || "";
    setGalleryData([]);

    const filters = [];

    if (searchParam) {
      filters.push({
        field: "description",
        value: searchParam,
        comparator: "search",
      });
    }
    setRequestParams((prev: Search.ISearchAssetsRequest) => ({
      ...prev,
      filters: filters,
      field: "",
      offset: 0,
      limit: CARD_LOAD_STEP,
    }));
  }, [searchParams]);

  useEffect(() => {
    getPhotos();
  }, [requestParams]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const scrollHandler = () => {
        if (
          window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 100 &&
          !isFetching &&
          !isLoading
        ) {
          setRequestParams((prev) => ({
            ...prev,
            offset: prev.offset + CARD_LOAD_STEP,
          }));
        }
      };

      window.addEventListener("scroll", scrollHandler);
      return () => window.removeEventListener("scroll", scrollHandler);
    }
  }, [isFetching, isLoading]);

  const applyFilters = (filters: Search.ISearchAssetsRequest) => {
    setGalleryData([]);
    setRequestParams(filters);
  };
  const getPhotos = async () => {
    flushSync(() => {
      setIsFetching(true);
    });

    await search(requestParams)
      .unwrap()
      .then((response) => {
        setGalleryData((prev) => [...prev, ...response]);
        setTimeout(() => {
          if (response.length !== 0) setIsFetching(false);
        }, 200);
      })
      .catch((response) => {
        handleServerResponse(response);
        setIsFetching(false);
      });
  };

  const onAlbumItemClick = (id: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("search");

    newSearchParams.set("category", id);
    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex w-full justify-center">
      <SearchFilters
        requestParams={requestParams}
        applyFilters={applyFilters}
      />
      <GalleryContainer
        loading={isLoading && galleryData.length === 0}
        notFound={galleryData.length === 0}
      >
        {galleryData.map((data) => (
          <>
            {data.files.map((meta, index) => {
              const id = findPhotoIdForGallery(meta?.objs);

              const shootDate = transformDate(meta?.shoot_dt);
              if (id)
                return (
                  <CardWithPhoto
                    id={id}
                    key={index}
                    onClick={() => onAlbumItemClick(data.id)}
                  >
                    <div className="flex flex-col">
                      {meta?.description?.split('//')[langId]}
                      <span>
                        {meta?.public_author} {shootDate.toString()}
                      </span>
                    </div>
                  </CardWithPhoto>
                );
            })}
          </>
        ))}
      </GalleryContainer>
    </div>
  );
};

export default AlbumsGallery;
