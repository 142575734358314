import React from "react";

export const PolyhexLogo = () => (
<svg 
    viewBox="1 0 147 40" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    width="147"
    height="40"
  >
  <g clipPath="url(#clip0_96_44)">
    <path d="M39.8318 19.9148C39.8318 17.0815 39.8818 14.2481 39.8318 11.3481C39.8318 10.2315 40.2652 9.53148 41.2152 8.99815C46.2985 6.16482 51.4318 3.26482 56.4652 0.38148C57.3818 -0.151853 58.1318 -0.101853 59.0318 0.38148C64.0652 3.21482 69.1485 6.11482 74.2318 8.89815C75.2985 9.48148 75.7318 10.2315 75.7318 11.4648C75.6818 17.1315 75.7318 22.8648 75.7318 28.5315C75.7318 29.5982 75.2985 30.2982 74.3985 30.8315C69.2652 33.7148 64.1318 36.5648 59.0485 39.4982C58.1985 39.9815 57.4485 39.9815 56.5818 39.4982C51.4485 36.6148 46.3152 33.7148 41.1652 30.8315C40.2485 30.2982 39.8318 29.5982 39.8318 28.5315C39.8818 25.6482 39.8318 22.7982 39.8318 19.9148ZM47.4818 19.8648C47.4818 21.5815 47.4818 23.2815 47.4818 24.9982C47.4818 25.6482 47.6985 26.0648 48.2818 26.3315C51.1652 27.9315 54.0652 29.5482 56.8985 31.1982C57.5485 31.5648 58.0818 31.5648 58.7152 31.1982C61.5485 29.5982 64.3818 27.9815 67.2318 26.3815C67.9318 26.0148 68.2485 25.5315 68.1985 24.7815C68.1985 21.5648 68.1985 18.3148 68.1985 15.0981C68.1985 14.3481 67.9318 13.8648 67.2318 13.4981C64.3985 11.9481 61.6152 10.3481 58.8318 8.73148C58.1318 8.29815 57.4985 8.29815 56.7985 8.73148C53.9652 10.3315 51.1318 11.9481 48.2818 13.5481C47.6318 13.8648 47.4818 14.2981 47.4818 14.9981C47.4818 16.5981 47.4818 18.1982 47.4818 19.8648Z" fill="#AE90FE"></path>
    <path d="M 91.734 37.839 C 96.337 37.839 103.682 37.838 108.283 37.838 C 109.04 37.838 109.41 37.731 109.41 36.938 C 109.363 35.504 109.285 34.004 109.379 32.567 C 109.427 31.658 109.064 31.549 108.307 31.598 C 105.423 31.65 99.742 31.478 96.857 31.579 C 95.802 31.627 95.438 31.363 95.438 30.141 C 95.485 21.383 95.438 12.579 95.485 3.82 C 95.485 2.815 95.234 2.55 94.272 2.599 C 92.507 2.647 90.677 2.647 88.912 2.599 C 88.109 2.55 87.904 2.863 87.904 3.656 C 87.951 12.512 87.904 21.319 87.951 30.175 C 87.951 31.281 87.91 32.911 87.935 34.468 C 87.978 37.143 88.274 37.779 88.937 37.794 C 94.065 37.909 87.084 37.839 91.734 37.839 Z" fill="white"></path>
    <path d="M 4.769 2.41 C 9.669 2.427 18.298 2.28 23.164 2.28 C 23.52 2.28 25.949 2.266 27.365 2.95 C 29.155 3.815 29.789 5.053 29.987 5.735 C 30.304 6.826 30.902 8.841 30.531 11.685 C 30.162 14.507 28.999 17.999 28.273 19.298 C 27.576 20.546 26.957 21.541 24.758 22.745 C 23.17 23.615 19.418 23.581 18.516 23.623 C 18.033 23.646 13.763 23.113 12.13 22.795 C 10.988 22.573 9.423 16.005 12.333 16.674 C 13.686 16.985 14.859 17.392 16.782 17.572 C 17.971 17.683 19.683 17.904 20.79 17.415 C 22.042 16.862 22.645 16.052 22.698 15.96 C 23.262 14.977 25.662 9.647 23.114 8.647 C 22.801 8.6 22.977 8.6 22.546 8.569 C 19.689 8.36 15.343 8.613 13.914 8.597 C 12.781 8.584 12.414 8.813 12.414 10.047 C 12.464 18.88 12.414 27.763 12.464 36.597 C 12.464 37.613 12.198 37.88 11.181 37.83 C 9.314 37.78 7.381 37.78 5.514 37.83 C 4.664 37.88 4.447 37.563 4.447 36.763 C 4.497 27.83 4.447 18.947 4.497 10.013 C 4.497 8.897 4.418 8.027 4.468 7.177 C 4.518 5.677 4.069 2.41 4.769 2.41 Z" fill="white"></path>
    <path d="M 129.202 12.679 C 129.872 12.679 130.209 12.083 130.61 11.702 C 131.074 11.261 131.516 10.556 132.303 9.566 C 133.912 7.542 135.895 5.182 137.466 3.282 C 138.121 2.491 138.607 1.737 139.096 1.164 C 139.94 0.175 142.474 2.745 142.953 3.12 C 143.374 3.45 144.143 3.848 143.88 4.587 C 142.715 7.858 136.425 14.654 133.869 17.476 C 133.463 17.924 132.916 18.77 132.911 18.968 C 132.795 23.267 132.911 31.015 132.961 37.022 C 132.961 37.714 132.695 37.895 131.678 37.861 C 129.811 37.827 127.878 37.827 126.012 37.861 C 125.162 37.895 124.945 37.679 124.945 37.135 C 124.995 31.061 124.945 25.02 124.995 18.945 C 124.995 18.841 124.583 18.166 124.164 17.657 C 121.652 14.606 116.589 7.31 115.771 4.885 C 115.697 4.667 115.263 3.834 115.109 3.446 C 115.084 3.381 115.042 3.417 115.072 3.16 C 115.12 2.746 119.34 0.386 119.922 0.921 C 122.09 2.912 124.296 7.057 126.683 10.042 C 127.274 10.781 127.611 11.375 127.977 11.763 C 128.283 12.087 128.69 12.679 129.202 12.679 Z" fill="white"></path>
  </g>
  <defs>
    <clipPath id="clip0_96_44">
      <rect width="147" height="40" fill="white" />
    </clipPath>
  </defs>
</svg>
);
