import React from "react";
import StoreProvider from "./StoreProvider";
import { NextUIProvider } from "@nextui-org/react";

type Props = {};
const Providers = (props: React.PropsWithChildren<Props>) => {
  return <StoreProvider>{props.children}</StoreProvider>;
};

export default Providers;
