import { Button, Divider, Input, Password } from "@/shared/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { FormEvent, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { signUpFormSchema } from "../schema/signUpFormSchema";
import { Users } from "@/entities/users/types";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {
  getData: (data: Users.ISignUp) => void;
};
export const SignUpForm = ({ getData }: Props) => {
  const [showErrors, setShowErrors] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigator = useNavigate();
  const invitation = searchParams.get("invitation");
  const form: UseFormReturn<Partial<Users.ISignUp>, UseFormProps> = useForm<
    Partial<Users.ISignUp>
  >({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(signUpFormSchema),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (!invitation) {
      navigator("/");
    }
  });
  const trimString = (
    name: "email" | "password" | "username" | "full_name",
  ) => {
    setValue(name, getValues(name)?.trim());
  };

  const onLogin = () => {
    const email = getValues("email");
    const password = getValues("password");
    const username = getValues("username");
    const full_name = getValues("full_name");

    getData({ email, password, invitation, username, full_name });
    setShowErrors(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setShowErrors(true);

    await handleSubmit(onLogin)(e);
  };

  return (
    <FormProvider {...form}>
      <form
        className="mx-auto flex w-[300px] flex-col gap-2 p-4"
        onSubmit={onSubmit}
      >
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <Input
              maxLength={30}
              id="email"
              placeholder="email"
              height="h-[30px]"
              value={value}
              onBlur={() => trimString("email")}
              onChange={(e) => {
                onChange(e);
              }}
              errorMessage={showErrors && errors?.email?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value } }) => (
            <Password
              maxLength={30}
              id="password"
              placeholder="password"
              height="h-[30px]"
              value={value}
              errorMessage={showErrors && errors?.password?.message}
              onChange={(e) => {
                onChange(e);
                trimString("password");
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="full_name"
          render={({ field: { onChange, value } }) => (
            <Input
              maxLength={30}
              id="full_name"
              placeholder="full_name"
              height="h-[30px]"
              value={value}
              onBlur={() => trimString("full_name")}
              onChange={(e) => {
                onChange(e);
              }}
              errorMessage={showErrors && errors?.full_name?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="username"
          render={({ field: { onChange, value } }) => (
            <Input
              maxLength={30}
              id="username"
              placeholder="username"
              autoComplete="off"
              height="h-[30px]"
              value={value}
              onBlur={() => trimString("username")}
              onChange={(e) => {
                onChange(e);
              }}
              errorMessage={showErrors && errors?.username?.message}
            />
          )}
        />
        <Button type="submit">Продолжить</Button>
      </form>
    </FormProvider>
  );
};
