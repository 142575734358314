import { useDownloadFileQuery } from "@/entities/assets/api/apiAssetsSlice";
import { Assets, IFileResponse } from "@/entities/assets/types";
import { DownloadIcon } from "@/shared/icons/24px";
import { Dropdown, Typography } from "@/shared/ui-kit";
import { Spinner } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { BACKEND_ENDPOINTS } from "@/shared/endpoints";

type Props = {
  data?: IFileResponse;
};
export const DownloadPhoto = ({ data }: Props) => {
  const [loading, setLoading] = useState(false);
  const [shouldDownloadLater, setShouldDownloadLater] = useState(false);
  const [selectedSize, setSelectedSize] = useState<Assets.TFileSize | null>(
    "normal",
  );
  const [selectedLogo, setSelectedLogo] = useState<Assets.TFileLogo | null>(
    "corner",
  );

  const sizesData = [
    { key: "original", label: "original" },
    { key: "normal", label: "normal" },
    { key: "medium", label: "medium" },
    { key: "small", label: "small" },
  ];
  const logoData = [
    { key: "without", label: "without" },
    { key: "pattern", label: "pattern" },
    { key: "central", label: "central" },
    { key: "corner", label: "corner" },
  ];

  useEffect(() => {
    if (!data) {
      setShouldDownloadLater(false);
    }
  }, [data]);

  useEffect(() => {
    if (shouldDownloadLater) downloadFile();
  }, [shouldDownloadLater]);

  const onDownloadClick = () => {
    setLoading(true);
    setShouldDownloadLater(true);
  };

  const downloadFile = async () => {
    try {
      while (shouldDownloadLater) {
        const accessToken = localStorage.getItem("access_token");
        const fileId = data.objs[0].file_id;
        const fileExtension = data.file_type;

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${BACKEND_ENDPOINTS.downloadFile({file_id: fileId, size: selectedSize, logo: selectedLogo})}`,
          {
            headers: accessToken
              ? { Authorization: `Bearer ${accessToken}` }
              : {},
          },
        );

        const json = await response.json();

        if (response.status != 200) {
          // showMsgOnUI("Непредвиденная ошибка, попробуйте позде")
          console.error("Unexpected error", json);
          break;
        }

        if (json?.status == "init") {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          continue;
        }

        if (json?.status != "success" || !json?.url) {
          // showMsgOnUI("Непредвиденная ошибка, попробуйте позде")
          console.error("Unexpected error", json);
          break;
        }

        const isTheSameHost =
          json.url.split("/")[2] == window.location.hostname;
        const fileResp = await fetch(json.url, {
          headers:
            accessToken && isTheSameHost
              ? { Authorization: `Bearer ${accessToken}` }
              : {},
        });
        const blob = await fileResp.blob();
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = href;
        a.download = `polyhex.${fileId}.${selectedSize}.${selectedLogo}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(href);
        break;
      }
    } catch (error) {
      console.error("Error while downloading file:", error);
      // showMsgOnUI("Непредвиденная ошибка, попробуйте позде")
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col gap-3">
      <Typography pattern="headline1">Скачать фото</Typography>
      <div className="flex w-full items-center gap-3">
        <Dropdown
          classNames="w-full"
          placeholder="Качество"
          disallowEmptySelection
          isClearable={false}
          type="single"
          list={sizesData}
          selectedKeys={new Set(selectedSize)}
          selectedValue={selectedSize}
          isSelections={!!selectedSize}
          onSelectClick={(e) => {
            const arraySelectedValues = Array.from(e).map(String);
            setSelectedSize(arraySelectedValues[0] as Assets.TFileSize);
          }}
          isDisabled={loading}
        />
        <Dropdown
          classNames="w-full"
          placeholder="Лого"
          type="single"
          isClearable={false}
          disallowEmptySelection
          list={logoData}
          selectedKeys={new Set(selectedLogo)}
          selectedValue={selectedLogo}
          isSelections={!!selectedLogo}
          onSelectClick={(e) => {
            const arraySelectedValues = Array.from(e).map(String);
            setSelectedLogo(arraySelectedValues[0] as Assets.TFileLogo);
          }}
          isDisabled={loading}
        />
        {loading ? (
          <Spinner color="secondary" className="w-full" />
        ) : (
          <div className="w-full bg-button-primary-bg-default hover:bg-button-primary-bg-hover active:bg-button-primary-bg-pressed focus-visible:bg-button-primary-bg-pressed h-10 rounded-lg flex items-center justify-center" onClick={onDownloadClick}>
            Скачать
            {/*<DownloadIcon />*/}
          </div>
        )}
      </div>
    </div>
  );
};
