import React, { useEffect, useState } from "react";
import { Assets, IFileResponse } from "@/entities/assets/types";
import langId from "@/entities/language";
import { GalleryContainer } from "@/shared/ui-kit";
import { useSearchParams } from "react-router-dom";
import { flushSync } from "react-dom";
import { Modal } from "@/shared/ui-kit";
import { ModalPhotoContent } from "./ui/modalPhotoContent";
import { CardWithPhoto } from "../getPhotoForCard";
import { transformDate } from "@/shared/utils/transformDate";
import { useSearchPhotosMutation } from "@/entities/search/api/apiSearchSlice";
import { isEmpty } from "lodash";
import { findPhotoIdForGallery } from "@/shared/utils/findPhotoIdWithSpecificQuality";


const PhotoGallery = () => {
  const STEP = 5;
  const [search, { data, isLoading }] = useSearchPhotosMutation();
  const [galleryData, setGalleryData] = useState<IFileResponse[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestParams, setRequestParams] = useState(null);

  const photo = searchParams.get("photo");
  const searchParam = searchParams.get("search") || "";
  const categoryParam = searchParams.get("category") || "";

  useEffect(() => {
    const filters = [];

    if (categoryParam) {
      filters.push({
        field: "file_set_id",
        value: `${categoryParam}`,
        comparator: "=",
      });
    }

    if (searchParam) {
      filters.push({
        field: "description",
        value: searchParam,
        comparator: "search",
      });
    }
    setGalleryData([]);
    setRequestParams((prev) => ({
      ...prev,
      filters: filters,
      field: "",
      offset: 0,
      limit: STEP,
    }));
  }, [searchParam, categoryParam]);

  useEffect(() => {
    getPhotos();
  }, [requestParams]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const scrollHandler = () => {
        if (
          window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 100 &&
          !isFetching &&
          !isLoading
        ) {
          setRequestParams((prev) => ({
            ...prev,
            offset: prev.offset + STEP,
          }));
        }
      };

      window.addEventListener("scroll", scrollHandler);
      return () => window.removeEventListener("scroll", scrollHandler);
    }
  }, [isFetching, isLoading]);

  const getPhotos = async () => {
    flushSync(() => {
      setIsFetching(true);
    });

    await search(requestParams)
      .unwrap()
      .then((response) => {
        setGalleryData((prev) => [...prev, ...response]);
        setTimeout(() => {
          if (response.length !== 0) setIsFetching(false);
        }, 200);
      })
      .catch(() => setIsFetching(false));
  };

  const removePageParam = () => {
    const currentParams = new URLSearchParams(searchParams);
    currentParams.delete("photo");
    setSearchParams(currentParams.toString());
  };

  const onPhotoItemClick = (data: IFileResponse) => {
    const currentParams = new URLSearchParams(searchParams);

    currentParams.set("photo", data?.objs[0]?.file_id.toString());

    setSearchParams(currentParams.toString());
  };

  return (
    <>
      <GalleryContainer
        loading={isLoading && galleryData.length === 0}
        notFound={galleryData.length === 0}
      >
        {galleryData.map((meta, index) => {
          const id = findPhotoIdForGallery(meta?.objs);
          const shootDate = transformDate(meta?.shoot_dt);
          if (id)
            return (
              <CardWithPhoto
                key={index}
                id={id}
                onClick={() => onPhotoItemClick(meta)}
              >
                <div className="flex flex-col">
                  {meta?.description?.split('//')[langId]}
                  <span>
                    {meta?.public_author} {shootDate.toString()}
                  </span>
                </div>
              </CardWithPhoto>
            );
        })}
        <Modal
          size="5xl"
          setActive={() => {}}
          onClose={removePageParam}
          open={!!photo}
        >
          <ModalPhotoContent />
        </Modal>
      </GalleryContainer>
    </>
  );
};

export default PhotoGallery;
