import { ServerErrorResponse } from "../types/ServerTypes";
import { errorNotify } from "../ui-kit";

export const handleServerResponse = (response: ServerErrorResponse) => {
  const isTooManyRequests = +response.status === 429;
  const isServerError = +response.status >= 500;
  const isNotAuthorized = +response.status === 401;
  const isUnprocessableEntity = +response.status === 422;

  if (
    !isTooManyRequests &&
    !isServerError &&
    !isNotAuthorized &&
    !isUnprocessableEntity &&
    response.status
  ) {
    errorNotify({
      title: response?.detail || "Ошибка",
    });
  }
};
