import React, { Dispatch, Key, SetStateAction } from "react";
import {
  Autocomplete as NextAutocomplete,
  AutocompleteItem,
  AutocompleteProps,
} from "@nextui-org/react";
import clsx from "clsx";
import { Typography } from "../Typography";

export type TAutocompleteValue = {
  label: string;
  value: string;
};
type Props = {
  id?: string;
  selectedKey?: string;
  onSelectionChange?: (key: Key) => void;
  label: string;
  list: TAutocompleteValue[];
  errorMessage?: string;
  isClearable?: boolean;
};
export const Autocomplete = ({
  id,
  selectedKey,
  onSelectionChange,
  list,
  label,
  errorMessage,
  isClearable,
  ...props
}: Props) => {
  return (
    <div className="flex w-full flex-col gap-1">
      <NextAutocomplete
        id={id}
        isClearable={isClearable}
        variant={"bordered"}
        selectedKey={selectedKey}
        defaultItems={list}
        label={label}
        onSelectionChange={onSelectionChange}
        className="w-full"
        inputProps={{
          classNames: {
            inputWrapper: clsx("h-10 py-1 rounded-lg border", {
              "border-stroke-default": !errorMessage,
              "border-stroke-error": errorMessage,
            }),
          },
        }}
        {...props}
      >
        {(item: TAutocompleteValue) => (
          <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>
        )}
      </NextAutocomplete>
      {errorMessage && (
        <div className="text-tiny text-red-500">{errorMessage}</div>
      )}
    </div>
  );
};
