import { createApi } from "@reduxjs/toolkit/query/react";

import { BACKEND_ENDPOINTS } from "src/shared/endpoints";
import { baseQueryWithReauth } from "src/shared/redux/services/apiSlice";
import { Search } from "../types";
import { IFileResponse } from "@/entities/assets/types";

export const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Search"],
  endpoints: (builder) => ({
    //queries
    getFilters: builder.query<Search.TFilter[], void>({
      query() {
        return BACKEND_ENDPOINTS.filters();
      },
    }),

    //mutations
    searchAlbum: builder.mutation<
      Search.TSetResponse[],
      Search.ISearchAssetsRequest
    >({
      query: ({ ...credentials }) => ({
        url: BACKEND_ENDPOINTS.searchPhotos(
          credentials?.limit_by_file_set === undefined ? 
          "": `?=limit_by_file_set=${credentials.limit_by_file_set}`
        ),
        method: "POST",
        body: credentials,
      }),
    }),
    searchPhotos: builder.mutation<
      IFileResponse[],
      Search.ISearchAssetsRequest
    >({
      query: ({ ...credentials }) => ({
        url: BACKEND_ENDPOINTS.searchAlbum(),
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Search"],
    }),
  }),
});

export const {
  //queries
  useGetFiltersQuery,

  //mutations
  useSearchAlbumMutation,
  useSearchPhotosMutation,
} = searchApi;
