import { emailRegExp } from "@/shared/constants/regExpressions";
import * as yup from "yup";

export const signUpFormSchema = yup.object().shape({
  email: yup
    .string()
    .required("Введите email")
    .matches(emailRegExp, "Введите корректный email"),
  password: yup
    .string()
    .required("Введите пароль")
    .min(8, "Пароль должен содержать минимум 8 символов")
    .matches(/[A-Z]/, "Пароль должен содержать хотя бы 1 заглавную букву")
    .matches(/[a-z]/, "Пароль должен содержать хотя бы 1 сторчную букву")
    .matches(/[@$!%*#?&]/, "Пароль должен содержать хотя бы 1 спецсимвол"),

  full_name: yup.string().required("Введите полное имя"),
  username: yup.string().required("Введите имя"),
});
