import React, { FC, PropsWithChildren } from "react";
import { Accordion as NextAccordion, AccordionItem } from "@nextui-org/react";
import { ChevronIcon } from "@/shared/icons/chevron";
import clsx from "clsx";

type Props = {
  header: string;
  additionalClasses?: string;
  onOpenChange?: () => void;
};
export const Accordion: FC<PropsWithChildren<Props>> = ({
  header,
  children,
  onOpenChange,
  additionalClasses,
}) => {
  return (
    <NextAccordion
      keepContentMounted
      onSelectionChange={onOpenChange}
      className={clsx("rounded-lg bg-accordion	px-2", additionalClasses)}
    >
      <AccordionItem
        classNames={{
          trigger: "py-3 ",
          content: "p-0",
        }}
        indicator={<ChevronIcon rotate="90" />}
        className="p-0"
        aria-label={header}
        title={header}
      >
        {children}
      </AccordionItem>
    </NextAccordion>
  );
};
