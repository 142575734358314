import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Typography } from "@/shared/ui-kit";
import { Assets } from "@/entities/assets/types";
import { CARD_LOAD_STEP } from "@/shared/constants/cardLoadStep";
import { RangeFilter } from "@/features/SearchFilters/filters/RangeFilter";
import { RadioGroup } from "./filters/RadioGroup";
import { CheckboxGroup } from "./filters/CheckboxGroup";
import { ChevronIcon } from "@/shared/icons/chevron";
import { useSearchParams } from "react-router-dom";
import { useGetFiltersQuery } from "@/entities/search/api/apiSearchSlice";
import { TFilterChange } from "./filters/types";
import { Search } from "@/entities/search/types";
import { transformSearchType } from "@/shared/utils/transformSearchType";
import { isArray } from "lodash";

type Props = {
  requestParams: Search.ISearchAssetsRequest;
  applyFilters: (filters: Search.ISearchAssetsRequest) => void;
};
export const SearchFilters = ({ requestParams, applyFilters }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isSearch = Boolean(searchParams.get("search"));

  const { data: filtersData } = useGetFiltersQuery();
  const radioFilters =
    filtersData
      ?.filter((data) => data.widget === "radiobutton")
      ?.map((data) => {
        return {
          title: data.field_title,
          field: data.field,
          comparator: data.comparator,
          selections: data.values.map((val) => {
            return { id: val.value.toString(), label: val.title };
          }),
        };
      }) || [];

  const checkboxFilters =
    filtersData
      ?.filter((data) => data.widget === "checkbox")
      ?.map((data) => {
        return {
          title: data.field_title,
          field: data.field,
          comparator: data.comparator,
          selections: data.values.map((val) => {
            return { id: val.value.toString(), label: val.title };
          }),
        };
      }) || [];

  const rangeFilters =
    filtersData
      ?.filter((data) => data.widget === "range")
      ?.map((data) => {
        return {
          title: data.field_title,
          field: data.field,
          comparator: data.comparator,
          selections: data.values.map((val) => {
            return { id: val.value.toString(), label: val.title };
          }),
        };
      }) || [];

  useEffect(() => {
    if (isSearch) {
      setIsOpen(true);
    }
  }, [isSearch]);

  const onFilterChange = ({ field, value, type }: TFilterChange) => {
    const mainFilter = filtersData.find((filter) => filter.widget === type);
    const comparator = mainFilter.comparator;
    const extraComparator = mainFilter.extra_comparator;

    const acceptType = mainFilter.accept_type.type;
    const isArrayAccept = Boolean(mainFilter.accept_type.list);

    let typedValue = null;

    if (isArray(value)) {
      if (value.length > 0)
        typedValue = value.map((item) => transformSearchType(item, acceptType));
    } else {
      typedValue = transformSearchType(value, acceptType);
    }

    const filters = requestParams.filters.filter(
      (item) => item.field !== field,
    );

    if (typedValue && !extraComparator) {
      filters.push({ field: field, comparator: comparator, value: typedValue });
    }
    if (typedValue && (typedValue[0] || typedValue[1]) && extraComparator) {
      filters.push({
        field: field,
        comparator: comparator,
        value: typedValue[0],
      });
      filters.push({
        field: field,
        comparator: extraComparator,
        value: typedValue[1],
      });
    }

    applyFilters({
      filters: filters,
      offset: 0,
      limit: CARD_LOAD_STEP,
    });
  };
  return (
    <div className="sticky top-[200px] flex h-fit">
      <div onClick={() => setIsOpen((prev) => !prev)}>
        <ChevronIcon pointer rotate={isOpen ? "90" : "-90"} />
      </div>

      {isOpen && (
        <div className="flex h-fit w-[264px] min-w-[264px] flex-col justify-center gap-6 bg-bg-lilaclight px-7 py-6">
          <Typography textAlign="center" pattern="headline1">
            Настройки поиска
          </Typography>
          {radioFilters?.map((filter, index) => (
            <RadioGroup
              key={index}
              filterData={filter}
              onFilterChange={onFilterChange}
            />
          ))}
          {checkboxFilters?.map((filter, index) => (
            <CheckboxGroup
              key={index}
              filterData={filter}
              onFilterChange={onFilterChange}
            />
          ))}

          {rangeFilters?.map((filter, index) => (
            <RangeFilter
              key={index}
              filterData={filter}
              onFilterChange={onFilterChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};
