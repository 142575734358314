import React from "react";
import { TIconProps } from "../ui/type";
import { IconContainer } from "../ui/iconContainer";

export function EyeOpenIcon(props: TIconProps) {
  return (
    <IconContainer size="16" {...props}>
      <path
        d="M7.99984 9.33073C8.73622 9.33073 9.33317 8.73378 9.33317 7.9974C9.33317 7.26102 8.73622 6.66406 7.99984 6.66406C7.26346 6.66406 6.6665 7.26102 6.6665 7.9974C6.6665 8.73378 7.26346 9.33073 7.99984 9.33073Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6668 8.0026C12.8888 11.1139 10.6668 12.6693 8.00016 12.6693C5.3335 12.6693 3.1115 11.1139 1.3335 8.0026C3.1115 4.89127 5.3335 3.33594 8.00016 3.33594C10.6668 3.33594 12.8888 4.89127 14.6668 8.0026Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}
