import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { SearchIcon } from "@/shared/icons";
import { Button } from "@/shared/ui-kit";

export function SearchLine() {
  const [searchValue, setSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const searchFromURL = searchParams.get("search") || "";
    // if (searchFromURL) {
    setSearchValue(searchFromURL);
    // }
  }, [searchParams]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      onClick();
    }
  };
  const onClick = () => {
    let newSearchParams = {};
    
    const categoryFromURL = searchParams.get("category");
    
    if (categoryFromURL) {
      newSearchParams = ({...newSearchParams, category: categoryFromURL });
    }

    if (searchValue) {
      newSearchParams = ({...newSearchParams, search: searchValue });
    }

    setSearchParams(newSearchParams);
  };

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value?.replace(/\s\s+/g, " ");
    setSearchValue(newValue);
  };

  return (
    <div className="sticky top-[130px] z-10 mx-auto flex h-[66px] w-fit">
      <div className="absolute left-[10px] top-1/2 z-10 -translate-y-1/2">
        <SearchIcon active />
      </div>

      <input
        className="bg-bg-white shadow-searchInput mx-auto block h-[66px] w-[60vw] max-w-[1128px] rounded-[10px]	border-0 px-[100px] py-2.5 pl-[60px] text-base italic "
        id="seacrh"
        maxLength={50}
        value={searchValue}
        onChange={onSearchChange}
        onKeyDown={handleKeyDown}
        placeholder="Например: название текущего снимка на бэкграунде"
      />
      <Button
        additionalClasses="absolute right-3 top-1/2 -translate-y-1/2 "
        onClick={onClick}
      >
        Искать
      </Button>
    </div>
  );
}
