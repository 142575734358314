import { Assets } from "@/entities/assets/types";

export const findPhotoIdForGallery = (photos: Assets.TFileObjMeta[]) => {
  if (!photos) return undefined;

  const smallPhotoId = photos
    .find((item) => item.size === "small")
    ?.id.toString();
  if (smallPhotoId) return smallPhotoId;

  const normalPhotoId = photos
    .find((item) => item.size === "normal")
    ?.id.toString();
  if (normalPhotoId) return normalPhotoId;

  return undefined;
};
