import { Button, Card, Typography } from "@/shared/ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/");
  };
  return (
    <div className="flex h-[calc(100vh-90px)] w-[100vw] flex-col items-center justify-center">
      <div className="w-[300px]">
        <Card bodyClasses="gap-8 flex flex-col items-center p-8">
          <Typography pattern="headline1">Страница не найдена</Typography>
          <Button onClick={onClick}>Вернуться на главную</Button>
        </Card>
      </div>
    </div>
  );
};
