import { Users } from "@/entities/users/types";
import { Card, successNotify } from "@/shared/ui-kit";
import { useInviteUserMutation } from "@/entities/users/api/apiUsersSlice";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import {
  InviteUserForm,
  TInviteUser,
} from "@/features/personalAccountForms/InviteUserForm";
import React from "react";

export const InviteUserTab = () => {
  const [invite, isLoading] = useInviteUserMutation();

  const onSubmit = async ({
    email,
    position,
  }: TInviteUser): Promise<Users.IInviteResponse> => {
    try {
      const data = await invite({ email, position }).unwrap();
      successNotify({ title: "Приглашение отправлено" });
      return data;
    } catch (response) {
      handleServerResponse(response);
      throw response;
    }
  };

  return (
    <Card bodyClasses="flex flex-col  p-10">
      <InviteUserForm onSubmit={onSubmit} />
    </Card>
  );
};
