import React, { useState } from "react";
import { EditSet } from "../../../features/SetEditor";
import { SetSelection } from "./SetSelection";
import { useSearchParams } from "react-router-dom";
import { useCreateFileMutation } from "@/entities/assets/api/apiAssetsSlice";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import {
  dataUrlToBlob,
  fileToBinary,
} from "@/shared/utils/preparePhotosForSending";
import { v4 as uuidv4 } from "uuid";
import { deleteKeyByValue } from "@/shared/utils/deleteKeyByValue";

export const SetsEditionTab = () => {
  const [localLoadingPhotosCount, setLocalLoadingPhotosCount] = useState(0);
  const [photoIdOrder, setPhotoIdOrder] = useState<string[]>([]);
  const [localReloadingPhotos, setLocalReloadingPhotos] = useState<string[]>(
    [],
  );
  const [errorFiles, setErrorFiles] = useState({});
  const [searchParams] = useSearchParams();
  const [createPhoto] = useCreateFileMutation();

  const querySetId = searchParams.get("set");

  const addPhotosToSet = async ({
    files,
    set_id,
    errorFileId,
  }: {
    files: File[];
    set_id: string;
    errorFileId?: string;
  }) => {
    const parallelFileUploadsCount =
      parseInt(process.env.REACT_APP_PARALLEL_FILE_UPLOADS_COUNT) || 4;
    const shouldLoadAll = parallelFileUploadsCount === -1;

    if (errorFileId) {
      setLocalReloadingPhotos((prev) => [...prev, errorFileId]);
    } else {
      setLocalLoadingPhotosCount(files.length);
    }
    const loadFiles = async (blob, index) => {
      const formData = new FormData();
      formData.append("file", blob, files[index].name);
      const data = JSON.stringify({
        file_set_id: set_id,
        file_type: files[index].type?.split("/")[1],
      });
      formData.append("data", data);

      // @ts-ignore
      await createPhoto(formData)
        .unwrap()
        .then((response) => {
          setPhotoIdOrder((prev) => [...prev, response.id.toString()]);
          setErrorFiles((prev) => {
            const newObj = { ...prev };
            deleteKeyByValue(newObj, errorFileId);

            return newObj;
          });
        })
        .catch((response) => {
          if (
            response?.status &&
            (+response.status >= 500 || response.status === "FETCH_ERROR") &&
            !Object.keys(errorFiles).includes(errorFileId)
          ) {
            const id = uuidv4();
            setErrorFiles((prev) => ({ ...prev, [id]: files[index] }));
            setPhotoIdOrder((prev) => [...prev, id]);
          }
          handleServerResponse(response);
        })
        .finally(() => {
          setLocalReloadingPhotos((prev) =>
            prev.filter((item) => item !== errorFileId),
          );
        });
    };

    const binaryBlobs = await Promise.all(
      files.map((file) =>
        fileToBinary(file).then((dataUrl) => dataUrlToBlob(dataUrl)),
      ),
    );
    if (shouldLoadAll) {
      binaryBlobs.map((blob, index) => {
        loadFiles(blob, index);
      });
      return;
    }

    for (let i = 0; i < binaryBlobs.length; i += parallelFileUploadsCount) {
      const batch = binaryBlobs.slice(i, i + parallelFileUploadsCount);
      const currentIndexes = Array.from(
        { length: batch.length },
        (_, k) => i + k,
      );

      await Promise.all(
        batch.map((blob, batchIndex) => {
          return loadFiles(blob, currentIndexes[batchIndex]);
        }),
      );
      setLocalLoadingPhotosCount((prev) => prev - parallelFileUploadsCount);
    }
  };

  if (querySetId) {
    return (
      <EditSet
        localReloadingPhotos={localReloadingPhotos}
        photoIdOrder={photoIdOrder}
        setPhotoIdOrder={setPhotoIdOrder}
        errorFiles={errorFiles}
        setErrorFiles={setErrorFiles}
        addPhotosToSet={addPhotosToSet}
        localLoadingPhotosCount={localLoadingPhotosCount}
      />
    );
  } else {
    return <SetSelection addPhotosToSet={addPhotosToSet} />;
  }
};
