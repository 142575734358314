import React from "react";
import { Snippet as NextSnippet } from "@nextui-org/react";

type Props = {
  text: string;
};
export const Snippet = ({ text }: Props) => {
  return (
    <NextSnippet
      variant="shadow"
      color="secondary"
      className="bg-main text-text-black"
    >
      {text}
    </NextSnippet>
  );
};
