import React, { FC, PropsWithChildren } from "react";
import { CardBody, Card as NextCard } from "@nextui-org/react";

type Props = {
  bodyClasses?: string;
};
export const Card: FC<PropsWithChildren<Props>> = ({
  children,
  bodyClasses,
}) => {
  return (
    <NextCard className="w-full border border-solid">
      <CardBody className={bodyClasses}>{children}</CardBody>
    </NextCard>
  );
};
