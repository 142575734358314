import { useMeQuery } from "@/entities/users/api/apiUsersSlice";
import { Card, Divider, Loader, Typography } from "@/shared/ui-kit";
import React from "react";

export const PersonalInfoTab = () => {
  const { data: userData, isLoading } = useMeQuery({ user_id: "current" });

  if (isLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <Card bodyClasses="flex w-full flex-col p-10 gap-6">
      <Typography>Имя : {userData?.full_name || ""}</Typography>
      <Divider />
      <Typography>Email : {userData?.email || ""}</Typography>
      <Divider />
      <Typography>Роль : {userData?.position || ""}</Typography>
    </Card>
  );
};
