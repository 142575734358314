import React, { FormEvent, useState } from "react";
import {
  Controller,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { Input, Button, Password } from "@/shared/ui-kit";
import { EyeCloseIcon, EyeOpenIcon } from "@/shared/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "./schema/loginSchema";
import { Users } from "@/entities/users/types";

type Props = {
  getData: (props: Partial<Users.ILogin>) => void;
};
export const LoginForm = ({ getData }: Props) => {
  const [showErrors, setShowErrors] = useState(false);

  const form: UseFormReturn<Partial<Users.ILogin>, UseFormProps> = useForm<
    Partial<Users.ILogin>
  >({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(loginSchema),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const trimString = (name: "email" | "password" | "totp") => {
    setValue(name, getValues(name)?.trim());
  };

  const onLogin = () => {
    const email = getValues("email");
    const password = getValues("password");
    const totp = getValues("totp");
    getData({ email, totp, password });
    setShowErrors(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setShowErrors(true);

    await handleSubmit(onLogin)(e);
  };

  return (
    <FormProvider {...form}>
      <form className="flex w-[250px] flex-col gap-2 p-4" onSubmit={onSubmit}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <Input
              maxLength={30}
              id="email"
              placeholder="email"
              height="h-[30px]"
              value={value}
              onBlur={() => trimString("email")}
              onChange={(e) => {
                onChange(e);
              }}
              errorMessage={showErrors && errors?.email?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value } }) => (
            <Password
              maxLength={30}
              id="password"
              placeholder="password"
              height="h-[30px]"
              value={value}
              errorMessage={showErrors && errors?.password?.message}
              onChange={(e) => {
                onChange(e);
                trimString("password");
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="totp"
          render={({ field: { onChange, value } }) => (
            <Input
              maxLength={30}
              id="totp"
              placeholder="totp"
              height="h-[30px]"
              value={value}
              onBlur={() => trimString("totp")}
              onChange={(e) => {
                onChange(e);
              }}
              errorMessage={showErrors && errors?.totp?.message}
            />
          )}
        />
        <Button type="submit">Войти</Button>
      </form>
    </FormProvider>
  );
};
