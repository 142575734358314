import React, { useState } from "react";
import { Popover as MuiPopover } from "@mui/material";
import { Button } from "@/shared/ui-kit/Button";

import { LoginForm } from "./loginForm";
import { useLoginMutation } from "@/entities/users/api/apiUsersSlice";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import { errorNotify } from "@/shared/ui-kit";
import { Users } from "@/entities/users/types";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { useNavigate } from "react-router-dom";
import { setAuth } from "@/shared/redux/services/authSlice";

export const LoginPopup = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { isLoading, isAuthenticated } = useAppSelector((state) => state.auth);

  const [login] = useLoginMutation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLoginData = ({ email, password, totp }: Partial<Users.ILogin>) => {
    login({
      email,
      password,
      totp: totp,
    })
      .unwrap()
      .then((response) => {
        setAnchorEl(null);
        localStorage.setItem("refresh_token", response.refresh_token);
        localStorage.setItem("access_token", response.access_token);
        navigate("/account");

        dispatch(setAuth());
      })
      .catch((response) => {
        handleServerResponse(response);
        const isNotAuthorized = +response.status === 401;
        if (isNotAuthorized)
          errorNotify({ title: "Неверный логин или пароль" });
      });
  };

  return (
    <>
      <Button pattern="secondary" onClick={handleClick}>
        {isAuthenticated ? "Выйти" : "Войти"}
      </Button>
      {/* Mui used for correct fixed position*/}
      <MuiPopover
        id={id}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {isAuthenticated ? (
          <div className="flex p-8">
            <Button pattern="delete">Выйти</Button>
          </div>
        ) : (
          <LoginForm getData={getLoginData} />
        )}
      </MuiPopover>
    </>
  );
};
