import React, { FC, PropsWithChildren } from "react";
import { ButtonProps, Button as NextButton } from "@nextui-org/react";
import clsx from "clsx";
import {
  BackgroundColor,
  BackgroundColorActive,
  BackgroundColorHover,
  Border,
  BorderRadius,
  Disabled,
  FontSize,
  FontWeight,
  Height,
  PaddingX,
  PaddingY,
  TextColor,
  TextColorActive,
  TextColorHover,
} from "./config";
import { TButtonPattern } from "./type";

interface Props extends ButtonProps {
  pattern?: TButtonPattern;
  width?: string;
  isDashed?: boolean;
  loading?: boolean;
  noBorder?: boolean;
  additionalClasses?: string;
}
export const Button: FC<PropsWithChildren<Props>> = ({
  children,
  pattern = "primary",
  size = "normal",
  width,
  disabled,
  loading,
  noBorder,
  additionalClasses,
  ...props
}) => {
  const isDisable = disabled || loading;
  const borderPosition = "border";

  const baseWithBorder = clsx(
    "flex justify-center items-center disabled:cursor-not-allowed",
    Height[size],
    BorderRadius[size],
    PaddingX[size],
    PaddingY[size],
    width,
  );

  const background = clsx(
    "gap-2",
    BackgroundColor[pattern],
    BackgroundColorHover[pattern],
    BackgroundColorActive[pattern],
  );

  const text = clsx(
    "font-medium",
    FontSize[size],
    FontWeight[size],
    TextColor[pattern],
    TextColorHover[pattern],
    TextColorActive[pattern],
  );
  const disabledClasses = isDisable && Disabled[pattern];

  const borderClasses = clsx(borderPosition, "border-solid", Border[pattern], {
    "border-none": noBorder,
  });
  const base = baseWithBorder;
  const classNames = clsx(
    base,
    background,
    text,
    borderClasses,
    additionalClasses,
    disabledClasses,
  );

  return (
    <NextButton
      classNames={{ base: classNames }}
      className={classNames}
      {...props}
    >
      {children}
    </NextButton>
  );
};
