import { Assets } from "@/entities/assets/types";

export const BACKEND_ENDPOINTS = {
  //users
  me: (user_id: string) => `/users/${user_id}/`,
  login: () => "/users/login/",
  logout: () => "/users/logout/",
  signup: () => "/users/signup/",
  refresh: () => "/users/refresh/",
  invite: () => "/users/invite/",
  getMe: (id: string) => `/users/${id}/`,
  resetPassword: (user_id: string) => `/users/${user_id}/password/`,

  //assets
  getOneFile: (id: string) => `/assets/file/${id}/`,
  getFileSet: (file_set_id: string) => `/assets/file_set/${file_set_id}/`,
  getFileObj: (obj_id: string) => `/assets/objs/file/${obj_id}/`,
  getFileObjSteam: (obj_id: string) => `/assets/objs/steam/${obj_id}/`,
  searchAlbum: () => "/assets/file/search/",
  searchPhotos: (query="") => `/assets/file_set/search/${query}`,
  editableFileInfo: () => `/assets/file/create/settings/`,
  editableFileSetInfo: () => `/assets/file_set/create/settings/`,
  newFile: () => "/assets/file/",
  file: (file_id: string) => `/assets/file/${file_id}/`,
  newFileSet: () => "/assets/file_set/",
  fileSet: (file_set_id: string) => `/assets/file_set/${file_set_id}/`,
  downloadFile: ({ file_id, size, logo }: Assets.IDownloadPhoto) =>
    `/assets/file/${file_id}/download/?size=${size}&logo=${logo}`,

  //search
  filters: () => `/search/settings/filters/`,
};
