import React, { useState } from "react";
import { Typography } from "../../../../shared/ui-kit/Typography";
import { transformObjectKey } from "@/shared/utils/transformObjectKey";
import { Checkbox } from "../../../../shared/ui-kit/Checkbox";
import { TSearchFilterField } from "@/shared/types/SearchFilterField";
import { TComparator } from "@/shared/types/Comparator";
import { TFilter, TFilterChange } from "../types";

type Props = {
  filterData: TFilter;
  onFilterChange: (data: TFilterChange) => void;
};

export const CheckboxGroup = ({ filterData, onFilterChange }: Props) => {
  const [value, setValue] = useState([]);

  const handleChange = (filterId: string) => {
    if (value.includes(filterId)) {
      const newValue = value.filter((item) => item !== filterId);
      setValue(newValue);
      onFilterChange({
        field: filterData.field,
        type: "checkbox",
        value: newValue,
      });
    } else {
      const newValue = [...value, filterId];
      setValue(newValue);
      onFilterChange({
        field: filterData.field,
        type: "checkbox",
        value: newValue,
      });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Typography pattern="headline1">
        {transformObjectKey(filterData.field)}
      </Typography>
      {filterData.selections.map((filterItem) => (
        <div className="flex cursor-pointer items-center" key={filterItem.id}>
          <Checkbox
            onChange={() => handleChange(filterItem.id)}
            checked={value.includes(filterItem.id)}
          >
            <Typography> {filterItem.label}</Typography>
          </Checkbox>
        </div>
      ))}
    </div>
  );
};
