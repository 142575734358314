import React from "react";
import AlbumsGallery from "@/features/AlbumsGallery";
import { useSearchParams } from "react-router-dom";
import PhotoGallery from "@/features/PhotoGallery";

export const Gallery = () => {
  const [params] = useSearchParams();
  const category = params.get("category") || "";
  if (category) {
    return <PhotoGallery />;
  }
  return <AlbumsGallery />;
};
