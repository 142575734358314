import React, { useState } from "react";
import { Input, InputProps } from "../Input";
import { EyeCloseIcon, EyeOpenIcon } from "@/shared/icons";

interface Props extends InputProps {}
export const Password = (props: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      maxLength={30}
      type={showPassword ? "text" : "password"}
      endContent={
        <div
          className="mb-2 flex cursor-pointer"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {showPassword ? <EyeCloseIcon pointer /> : <EyeOpenIcon pointer />}
        </div>
      }
      {...props}
    />
  );
};
