import { createApi } from "@reduxjs/toolkit/query/react";

import { BACKEND_ENDPOINTS } from "src/shared/endpoints";
import { baseQueryWithReauth } from "src/shared/redux/services/apiSlice";
import { Assets, IFileResponse } from "../types";

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["FileSet"],
  endpoints: (builder) => ({
    //queries
    getOneFile: builder.query<IFileResponse, Assets.IFileWithId>({
      query({ id }) {
        return BACKEND_ENDPOINTS.getOneFile(id);
      },
    }),
    getFileSet: builder.query<Assets.IFileSet, { file_set_id: string }>({
      query({ file_set_id }) {
        return BACKEND_ENDPOINTS.getFileSet(file_set_id);
      },
      providesTags: ["FileSet"],
    }),
    getFileObj: builder.query<Blob, Assets.IFileWithId>({
      query({ id }) {
        return BACKEND_ENDPOINTS.getFileObj(id);
      },
    }),
    getFileObjSteam: builder.query<string, Assets.IFileWithId>({
      query({ id }) {
        return BACKEND_ENDPOINTS.getFileObjSteam(id);
      },
    }),
    getEditableFileInfo: builder.query<Assets.IEditableFileInfo, void>({
      query() {
        return BACKEND_ENDPOINTS.editableFileInfo();
      },
    }),
    getEditableFileSetInfo: builder.query<Assets.IEditableFileInfo, void>({
      query() {
        return BACKEND_ENDPOINTS.editableFileSetInfo();
      },
    }),
    downloadFile: builder.query<void, Assets.IDownloadPhoto>({
      query(params) {
        return BACKEND_ENDPOINTS.downloadFile(params);
      },
    }),

    //mutations
    createFile: builder.mutation<IFileResponse, Assets.ICreateFile>({
      query: (body) => ({
        url: BACKEND_ENDPOINTS.newFile(),
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FileSet"],
    }),
    deleteFile: builder.mutation<void, Assets.IDeleteFile>({
      query: ({ file_id, ...body }) => ({
        url: BACKEND_ENDPOINTS.file(file_id),
        method: "DELETE",
        body: body,
      }),
      invalidatesTags: ["FileSet"],
    }),
    createFileSet: builder.mutation<
      Assets.IFileSetCreateResponse,
      Assets.IFileSetCreateRequest
    >({
      query: (body) => ({
        url: BACKEND_ENDPOINTS.newFileSet(),
        method: "POST",
        body: body || { files: [] },
      }),
    }),
    updateFileSet: builder.mutation<
      IFileResponse,
      Assets.IFileSetUpdateRequest
    >({
      query: ({ file_set_id, ...credentials }) => ({
        url: BACKEND_ENDPOINTS.fileSet(file_set_id),
        method: "PATCH",
        body: credentials,
      }),
    }),
    updateOneFile: builder.mutation<IFileResponse, Assets.IFileUpdateRequest>({
      query: ({ id, ...credentials }) => ({
        url: BACKEND_ENDPOINTS.getOneFile(id),
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["FileSet"],
    }),
  }),
});

export const {
  //queries
  useGetOneFileQuery,
  useGetFileObjQuery,
  useGetFileSetQuery,
  useGetFileObjSteamQuery,
  useGetEditableFileInfoQuery,
  useGetEditableFileSetInfoQuery,
  useDownloadFileQuery,

  // mutations
  useCreateFileMutation,
  useDeleteFileMutation,
  useCreateFileSetMutation,
  useUpdateFileSetMutation,
  useUpdateOneFileMutation,
} = assetsApi;
