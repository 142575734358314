import { Users } from "@/entities/users/types";
import {
  Autocomplete,
  Button,
  Dropdown,
  Input,
  Link,
  Snippet,
  Typography,
} from "@/shared/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { FormEvent, useState } from "react";
import {
  Controller,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { inviteUserSchema } from "../schema/inviteUserSchema";

export type TInviteUser = {
  email: string;
  position: Users.TUserRole;
};
type Props = {
  onSubmit: (value: TInviteUser) => Promise<Users.IInviteResponse>;
};
export const InviteUserForm = ({ onSubmit: onFormSubmit }: Props) => {
  const [showErrors, setShowErrors] = useState(false);
  const [invitation, setInvitation] = useState("");

  const rolesList = [
    { value: "admin", label: "Администратор" },
    { value: "2", label: "Пользователь" },
    { value: "3", label: "Кто-то еще" },
  ];
  const form: UseFormReturn<Partial<Users.ISendInvite>, UseFormProps> = useForm<
    Partial<Users.ISendInvite>
  >({
    mode: "onChange",
    reValidateMode: "onChange",
    // @ts-ignore
    resolver: yupResolver(inviteUserSchema),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const trimString = (name: "email" | "position") => {
    setValue(name, getValues(name)?.trim());
  };
  const onReset = () => {
    const email = getValues("email");
    const position = getValues("position");

    onFormSubmit({ email, position }).then((data) => {
      setInvitation(data.invitation);
      setShowErrors(false);
    });
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setShowErrors(true);
    await handleSubmit(onReset)(e);
  };
  return (
    <FormProvider {...form}>
      <form
        className="flex w-full max-w-[500px] flex-col items-start gap-6 p-4"
        onSubmit={onSubmit}
      >
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <Input
              id="email"
              height="h-10"
              placeholder="Email"
              value={value}
              onBlur={() => trimString("email")}
              onChange={(e) => {
                onChange(e);
              }}
              errorMessage={showErrors && errors?.email?.message}
            />
          )}
        />

        <div className="w-full">
          <Controller
            control={control}
            name="position"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="position"
                label="Роль"
                selectedKey={value}
                onSelectionChange={onChange}
                list={rolesList}
                errorMessage={showErrors && errors?.position?.message}
              />
            )}
          />
        </div>

        <div>
          <Button type="submit">Пригласить</Button>
        </div>
      </form>
      {/* TODO: remove  */}
      <div className="mt-8 flex flex-col gap-8">
        {invitation && (
          <div className="flex w-fit items-center gap-8">
            <Typography textWrap="text-nowrap">Новый invite_id</Typography>
            <Snippet text={invitation} />
          </div>
        )}
        {invitation && (
          <Link isExternal href={`/auth/signup?invitation=${invitation}`}>
            Перейти к подтверждению
          </Link>
        )}
      </div>
    </FormProvider>
  );
};
