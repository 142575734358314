import {
  useGetFileObjQuery,
  useGetOneFileQuery,
} from "@/entities/assets/api/apiAssetsSlice";
import { IFileResponse } from "@/entities/assets/types";

import React, { useEffect, useState } from "react";

import { PhotoMeta } from "./PhotoMeta";
import { Loader } from "@/shared/ui-kit";
import { DownloadPhoto } from "./DownloadPhoto";
import { useSearchParams } from "react-router-dom";

export const ModalPhotoContent = () => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const photo = searchParams.get("photo");

  const { data } = useGetOneFileQuery({ id: photo }, { skip: !photo });
  const id = data?.objs.find((photo) => photo.size === "normal")?.id.toString();
  const { data: imageData } = useGetFileObjQuery({
    id: id,
  });

  useEffect(() => {
    if (imageData) {
      const url = URL.createObjectURL(imageData);
      setImageUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [imageData]);

  return (
    <div className="flex flex-col w-full items-center gap-[20px] rounded-lg bg-bg-white p-5">
      <div className="flex h-full w-full flex-col justify-center gap-5">
        {imageUrl ? (
          <img
            className="block max-w-full object-contain"
            src={imageUrl}
            alt="img"
          />
        ) : (
          <Loader />
        )}
        <DownloadPhoto data={data} />
      </div>
      <div className="flex w-full flex-col gap-3">
        <PhotoMeta data={data} />
      </div>
    </div>
  );
};
