import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { MainPage } from "./Albums";
import Account from "./Account";
import SignUp from "./SignUp";
import Header from "@/widgets/Header";
import withAccessCheck from "@/shared/lib/useAccess";
import { useAppDispatch } from "@/shared/redux/hooks";
import { useRefreshTokenMutation } from "@/entities/users/api/apiUsersSlice";
import {
  finishInitialLoad,
  logout,
  setAuth,
} from "@/shared/redux/services/authSlice";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import { NotFoundPage } from "./NotFoundPage";

export function Pages() {
  const AccountWithAccessCheck = withAccessCheck(Account);
  const dispatch = useAppDispatch();
  const session_key = localStorage.getItem("refresh_token");
  const [refresh] = useRefreshTokenMutation();

  useEffect(() => {
    if (session_key && refresh && dispatch) {
      refresh({ refresh_token: session_key })
        .unwrap()
        .then(() => {
          dispatch(setAuth());
        })
        .catch((error) => {
          logout();
          handleServerResponse(error);
        })
        .finally(() => {
          dispatch(finishInitialLoad());
        });
    }
  }, []);
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/account/*" element={<AccountWithAccessCheck />} />
        <Route path="/auth/signup" element={<SignUp />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}
