export enum BackgroundColor {
  primary = "bg-button-primary-bg-default",
  secondary = "bg-button-secondary-bg-default",
  delete = "bg-button-delete-bg-default",
}

export enum BackgroundColorHover {
  primary = "hover:bg-button-primary-bg-hover",
  secondary = "hover:bg-button-secondary-bg-hover",
  delete = "hover:bg-button-delete-bg-hover",
}

export enum BackgroundColorActive {
  primary = "active:bg-button-primary-bg-pressed focus-visible:bg-button-primary-bg-pressed",
  secondary = "active:bg-button-secondary-bg-pressed focus-visible:bg-button-secondary-bg-pressed",
  delete = "active:bg-button-delete-bg-pressed focus-visible:bg-button-delete-bg-pressed",
}

export enum TextColor {
  primary = "text-button-primary-text-default",
  secondary = "text-button-secondary-text-default",
  delete = "text-button-delete-text-default",
}

export enum TextColorHover {
  primary = "hover:text-button-primary-text-default",
  secondary = "hover:text-button-secondary-text-default",
  delete = "hover:text-button-delete-text-hover",
}

export enum TextColorActive {
  primary = "active:text-button-primary-text-default focus-visible:text-button-primary-text-default",
  secondary = "active:text-button-secondary-text-default focus-visible:text-button-secondary-text-default",
  delete = "active:text-button-delete-text-default focus-visible:text-button-delete-text-default",
}

export enum Border {
  primary = "border-none",
  secondary = "border-none",
  delete = "border-none",
}

export enum Disabled {
  primary = "disabled:bg-button-primary-bg-disabled disabled:text-button-primary-text-disabled",
  secondary = "disabled:bg-button-secondary-bg-disabled disabled:text-button-secondary-text-disabled",
}

export const BorderRadius = {
  normal: "rounded-[10px]",
};

export const PaddingX = {
  normal: "px-8",
};

export const PaddingY = {
  normal: "py-2",
};

export const Height = {
  normal: "h-10",
};

export const FontSize = {
  normal: "text-base", //16px
};
export const FontWeight = {
  normal: "font-normal	", //400
};
