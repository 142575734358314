import { Users } from "@/entities/users/types";
import { Button, Password } from "@/shared/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { FormEvent } from "react";
import {
  Controller,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { resetPasswordSchema } from "../schema/resetPasswordSchema";
import { useResetPasswordMutation } from "@/entities/users/api/apiUsersSlice";

export type TResetPassword = {
  old_password: string;
  new_password: string;
};
type Props = {
  onSubmit: (value: TResetPassword) => void;
};
export const ResetPasswordForm = ({ onSubmit: onFormSubmit }: Props) => {
  const form: UseFormReturn<
    Partial<Users.IResetPassword>,
    UseFormProps
  > = useForm<Partial<Users.IResetPassword>>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(resetPasswordSchema),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const trimString = (name: "old_password" | "new_password") => {
    setValue(name, getValues(name)?.trim());
  };
  const onReset = () => {
    const old_password = getValues("old_password");
    const new_password = getValues("new_password");
    onFormSubmit({ old_password, new_password });
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    await handleSubmit(onReset)(e);
  };

  return (
    <FormProvider {...form}>
      <form
        className="flex w-full  flex-col items-start gap-6"
        onSubmit={onSubmit}
      >
        <Controller
          control={control}
          name="old_password"
          render={({ field: { onChange, value } }) => (
            <Password
              id="old_password"
              height="h-10"
              label="Текущий пароль"
              value={value}
              onBlur={() => trimString("old_password")}
              onChange={(e) => {
                onChange(e);
              }}
              errorMessage={errors?.old_password?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="new_password"
          render={({ field: { onChange, value } }) => (
            <Password
              id="new_password"
              height="h-10"
              label="Новый пароль"
              value={value}
              onBlur={() => trimString("new_password")}
              onChange={(e) => {
                onChange(e);
              }}
              errorMessage={errors?.new_password?.message}
            />
          )}
        />

        <div>
          <Button type="submit">Изменить пароль</Button>
        </div>
      </form>
    </FormProvider>
  );
};
