export const fileToBinary = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const dataUrlToBlob = (dataUrl) => {
  const base64Data = dataUrl.split(",")[1];
  const byteString = atob(base64Data);

  const byteArray = new Uint8Array(
    byteString.split("").map((char) => char.charCodeAt(0)),
  );

  return new Blob([byteArray], { type: "image/jpeg" });
};
