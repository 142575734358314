import { emailRegExp } from "@/shared/constants/regExpressions";
import * as yup from "yup";

export const inviteUserSchema = yup.object().shape({
  email: yup
    .string()
    .required("Введите email")
    .matches(emailRegExp, "Введите корректный email"),
  position: yup.string().required("Введите роль"),
});
