import React from "react";

import { IconContainer } from "./ui/iconContainer";
import { TIconProps } from "./ui/type";

export const ChevronIcon = (props: TIconProps) => (
  <IconContainer {...props}>
    <g clipPath="url(#clip0_734_46466)">
      <path
        d="M6 9L12 15L18 9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_734_46466">
        <rect width="24" height="24" className="fill-bg-white" />
      </clipPath>
    </defs>
  </IconContainer>
);
