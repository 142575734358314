import React from "react";
import { IconContainer } from "../ui/iconContainer";
import { TIconProps } from "../ui/type";

export const DownloadIcon = (props: TIconProps) => (
  <IconContainer {...props}>
    <path
      d="M16.4444 8C18.5214 8 19.5598 8 20.3333 8.48231C20.8401 8.79827 21.2609 9.25273 21.5534 9.8C22 10.6354 22 11.7569 22 14C22 16.2431 22 17.3646 21.5534 18.2C21.2609 18.7473 20.8401 19.2017 20.3333 19.5177C19.5598 20 18.5214 20 16.4444 20H7.55556C5.47863 20 4.44017 20 3.66667 19.5177C3.15994 19.2017 2.73914 18.7473 2.44658 18.2C2 17.3646 2 16.2431 2 14C2 11.7569 2 10.6354 2.44658 9.8C2.73914 9.25273 3.15994 8.79827 3.66667 8.48231C4.44017 8 5.47863 8 7.55556 8"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12 4L12 14.5M12 14.5L14.5 12M12 14.5L9.5 12"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </IconContainer>
);
