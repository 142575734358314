import React from "react";
import {
  Button,
  Dropdown as NextDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from "@nextui-org/react";
import clsx from "clsx";
import { ReactNode, UIEventHandler, useEffect, useRef, useState } from "react";

import { Checkbox, Typography } from "../..";

import { TDropdownList } from "..";
import { ChevronIcon, CloseIcon } from "@/shared/icons";

export type TDropdownViewProps = {
  id?: string;
  list: TDropdownList;
  type: "multiple" | "single";
  placeholder: string;
  required?: boolean;
  trigger?: ReactNode;
  disallowEmptySelection?: boolean;
  isClearable?: boolean;
  isFillable?: boolean;
  isSelections?: boolean;
  belowContent?: ReactNode;
  fixedContent?: ReactNode;
  isDisabled?: boolean;
  buttonTriggerClasses?: string;
  errorMessage?: string;

  onScroll?: UIEventHandler<HTMLUListElement>;
};
type Props = {
  selectedValue?: string;
  onSelect: (e: Set<React.Key>) => void;
  onClean: () => void;
  selectedKeys?: Set<React.Key> | "all";
  isError?: boolean;
};
export const DropdownView = ({
  id,
  list,
  type,
  placeholder,
  trigger,
  disallowEmptySelection,
  isClearable = true,
  isDisabled = false,
  errorMessage,
  buttonTriggerClasses,
  selectedKeys,
  selectedValue,
  belowContent,
  fixedContent,
  onClean,
  onSelect,
  onScroll,
  isSelections: defaultIsSelections,
}: TDropdownViewProps & Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [listWidth, setListWidth] = useState("");
  const [hoverItem, setHoverItem] = useState<null | number>(null);

  const baseButtonClasses =
    "h-10 w-full rounded-lg border-stroke-default border hover:border-stroke-hover border-solid bg-bg-white";

  const buttonClasses = clsx(baseButtonClasses, {
    "border-stroke-error hover:border-stroke-error ": errorMessage,
  });

  const isSingle = type === "single";
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const isSelections =
    defaultIsSelections ||
    (selectedKeys && selectedKeys !== "all" && selectedKeys.size > 0);
  const isAllSelected = selectedKeys === "all";

  useEffect(() => {
    if (buttonRef?.current) {
      const width = `${buttonRef?.current.offsetWidth}px`;
      setListWidth(width);
    }
  }, [buttonRef?.current?.offsetWidth, isFocused]);

  const onOpenChange = (isOpen: boolean) => setIsFocused(isOpen);
  return (
    <NextDropdown
      classNames={{
        content: `rounded-lg min-w-[170px] overflow-hidden`,
      }}
      className={`w-full p-0`}
      onOpenChange={onOpenChange}
      closeOnSelect={type === "single"}
    >
      <DropdownTrigger>
        {trigger ? (
          trigger
        ) : (
          <Button
            ref={buttonRef}
            variant="bordered"
            className={clsx(buttonClasses, buttonTriggerClasses)}
            isDisabled={isDisabled}
            endContent={
              isSelections && isClearable ? (
                <CloseIcon onClick={onClean} />
              ) : (
                <ChevronIcon rotate={isFocused ? "180" : undefined} />
              )
            }
          >
            <div className="flex w-full flex-col">
              {selectedValue ? (
                <Typography>{selectedValue}</Typography>
              ) : (
                <Typography color="text-text-lightGary">
                  {placeholder}
                </Typography>
              )}
            </div>
          </Button>
        )}
      </DropdownTrigger>
      <DropdownMenu
        id={id}
        aria-label="selection"
        variant="bordered"
        emptyContent={
          <div className="flex justify-center px-4 py-3">
            <Typography textAlign="center">{"Empty"}</Typography>
          </div>
        }
        style={{ width: listWidth }}
        classNames={{
          base: `p-0`,
          list: "gap-0 p-0 max-h-[320px] overflow-y-scroll",
          emptyContent: "p-0 ",
        }}
        closeOnSelect={type === "single"}
        selectionMode={type}
        disallowEmptySelection={disallowEmptySelection}
        // @ts-ignore
        selectedKeys={selectedKeys}
        // @ts-ignore
        onSelectionChange={onSelect}
      >
        <DropdownSection classNames={{ base: "mb-0 " }}>
          {list.map((item, index) => {
            const isInSelected =
              (!isAllSelected && selectedKeys?.has(item.key)) || false;
            return (
              <DropdownItem
                textValue={item.label}
                className="flex h-10 items-center overflow-visible rounded-none border-0 px-4 py-3 hover:bg-bg-lilaclight"
                key={item.key}
                onMouseEnter={() => setHoverItem(index)}
                onMouseLeave={() => setHoverItem(null)}
              >
                {!isSingle ? (
                  <Checkbox
                    checked={isInSelected}
                    isHover={index === hoverItem}
                  >
                    <Typography
                      pattern="body1"
                      textWrap="text-nowrap"
                      color={isSingle && isInSelected ? "text-main" : undefined}
                    >
                      {item.label}
                    </Typography>
                  </Checkbox>
                ) : (
                  <Typography
                    pattern="body1"
                    color={isSingle && isInSelected ? "text-main" : undefined}
                  >
                    {item.label}
                  </Typography>
                )}
              </DropdownItem>
            );
          })}
        </DropdownSection>
        <DropdownSection className={clsx("mb-3", { hidden: !belowContent })}>
          <DropdownItem textValue={"below"} hideSelectedIcon>
            {belowContent}
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </NextDropdown>
  );
};
