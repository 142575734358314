import { BACKEND_ENDPOINTS } from "src/shared/endpoints";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { logout, setAuth } from "./authSlice";
import getAccessToken from "@/entities/users/api/services/getAccessToken";

const mutex = new Mutex();
export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  credentials: "same-origin",
  prepareHeaders: (headers, { getState }) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
  responseHandler: async (response) => {
    if (response.headers.get("Content-Type")?.includes("image")) {
      return response.blob();
    }
    return response.json();
  },
});
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  const url = (args as FetchArgs).url as string;
  const loginUrl = BACKEND_ENDPOINTS.login();
  const isUrlRequest = url === loginUrl;

  if (
    result.error &&
    ((result.error.status === 401 && !isUrlRequest) ||
      result.error.status === 422)
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const refreshToken = localStorage.getItem("refresh_token");

      try {
        const refreshResult = await baseQuery(
          {
            url: BACKEND_ENDPOINTS.refresh(),
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
              Authorization: `Bearer ${refreshToken}`,
            },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          api.dispatch(setAuth());
          const accessToken = (refreshResult.data as { access_token: string })
            ?.access_token;
          localStorage.setItem("access_token", accessToken);

          result = await baseQuery(args, api, extraOptions);
        } else {
          if (result.error.status === 422) {
            const isAuth = await getAccessToken();
            if (isAuth) api.dispatch(setAuth());
            if (!isAuth) api.dispatch(logout());
          }
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
