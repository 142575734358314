import React from "react";
import ReactQRCode from "react-qr-code";

type Props = {
  value: string;
  size?: number;
};
export const QRCode = ({ value, size = 128 }: Props) => {
  return (
    <ReactQRCode
      size={size}
      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
      value={value}
      viewBox={`0 0 ${size} ${size}`}
    />
  );
};
