import React, { FC, PropsWithChildren, useEffect, useState } from "react";

import { useGetFileObjQuery } from "@/entities/assets/api/apiAssetsSlice";
import { PhotoCard } from "@/shared/ui-kit";
type Props = {
  id: string;
  onClick: () => void;
};
export const CardWithPhoto: FC<PropsWithChildren<Props>> = ({
  children,
  id,
  onClick,
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const { data: imageData } = useGetFileObjQuery({
    id: id,
  });

  useEffect(() => {
    if (imageData) {
      const url = URL.createObjectURL(imageData);
      setImageUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [imageData]);

  return (
    <PhotoCard onClick={onClick} imageUrl={imageUrl}>
      {children}
    </PhotoCard>
  );
};
