import clsx from "clsx";
import { TRotate } from "./type";

type Props = {
  disabled?: boolean;
  active?: boolean;
  hover: boolean;
};
export const iconStrokeClass = ({ disabled, hover, active }: Props) => {
  return clsx({
    "stroke-stroke-icons-disabled": disabled,
    "stroke-stroke-icons-active": active && !disabled,
    "stroke-stroke-icons-hover": hover && !disabled && !active,
    "stroke-stroke-icons-default": !hover && !disabled && !active,
  });
};
export const rotateClasses = (rotate?: TRotate) => {
  switch (rotate) {
    case "90":
      return "rotate-90";
    case "180":
      return "rotate-180";
    case "-90":
      return "-rotate-90";
    default:
      return "";
  }
};
