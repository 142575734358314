import { Loader } from "@/shared/ui-kit";
import React from "react";

export const PhotoLoading = () => {
  return (
    <div className="flex aspect-3/2 w-[130px] justify-center">
      <Loader />
    </div>
  );
};
