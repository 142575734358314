import React from "react";
import { Input, InputProps } from "../Input";

type Props = {
  type: "float" | "str" | "int" | "datetime";
  onChange: (value: string) => void;
} & InputProps;
export const InputByType = ({ type, onChange, ...props }: Props) => {
  switch (type) {
    case "str":
      return <Input onChange={onChange} {...props} />;
    case "int":
      return <Input numeric onChangeNumeric={onChange} {...props} />;
    case "float":
      return <Input float onChangeNumeric={onChange} {...props} />;
    default:
      return <Input onChange={onChange} {...props} />;
  }
};
