import clsx from "clsx";
import { PropsWithChildren } from "react";
import React from "react";
import { ITypography } from "./types";
import {
  FontSizeDesktop,
  FontSizeMobile,
  FontSizeTablet,
  FontWeightDesktop,
  FontWeightMobile,
  FontWeightTablet,
  LineHeightDesktop,
  LineHeightMobile,
  LineHeightTablet,
  TextAlign,
} from "./config";

export const Typography: React.FC<PropsWithChildren<ITypography>> = ({
  pattern = "body1",
  color = "",
  textAlign = "left",
  display,
  textWrap = "text-wrap",
  lineClamp,
  tagName = "p",
  hasTruncate = true,
  additionalClasses,
  textEllipsis,
  italic,
  ...props
}) => {
  let TagName = tagName;

  if (pattern.match("headline")) {
    TagName = "h1";
  }

  const textAlignClassname = TextAlign[textAlign];
  const fontWeightClassname = `${FontWeightMobile[pattern]} 
  ${FontWeightTablet[pattern]} ${FontWeightDesktop[pattern]}`;
  const fontSizeClassname = `${FontSizeMobile[pattern]} ${FontSizeTablet[pattern]} ${FontSizeDesktop[pattern]}`;
  const lineHeightClassname = `${LineHeightMobile[pattern]} ${LineHeightTablet[pattern]} ${LineHeightDesktop[pattern]}`;

  return (
    <TagName
      className={clsx(
        textAlignClassname,
        fontWeightClassname,
        fontSizeClassname,
        lineHeightClassname,
        color,
        display,
        textWrap,
        lineClamp,
        additionalClasses,
        {
          truncate: hasTruncate,
          "overflow-hidden text-ellipsis": textEllipsis,
          italic: italic,
        },
      )}
      {...props}
    />
  );
};
