import { TAcceptType } from "@/entities/types";

export const transformSearchType = (
  value: string | number,
  accept_type: TAcceptType,
) => {
  if (accept_type === "str") {
    return value ? value.toString() : null;
  }
  if (accept_type === "int" || accept_type === "float") {
    return value ? Number(value) : null;
  }
};
