import {
  useCreateFileSetMutation,
  useUpdateFileSetMutation,
} from "@/entities/assets/api/apiAssetsSlice";
import { useSearchAlbumMutation } from "@/entities/search/api/apiSearchSlice";
import { Search } from "@/entities/search/types";
import {
  PHOTO_ACCEPTED_FORMATS,
  MEDIA_MAX_SIZE,
  VIDEO_ACCEPTED_FORMATS,
} from "@/shared/constants/uploadedPhotoParams";
import { Card, Dropzone, Loader, Typography } from "@/shared/ui-kit";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import clsx from "clsx";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import langId from "@/entities/language";
import { PhotoCard } from "@/shared/ui-kit";
import {CardWithPhoto} from "@/features/getPhotoForCard";

type Props = {
  addPhotosToSet: ({
    files,
    set_id,
  }: {
    files: File[];
    set_id: string;
  }) => Promise<void>;
};
export const SetSelection = ({ addPhotosToSet }: Props) => {
  const [fileSets, setFileSets] = useState<Search.TSetResponse[]>([]);

  const [createFileSet, { isLoading: isSetCreationLoading }] =
    useCreateFileSetMutation();
  const [search, { data, isLoading }] = useSearchAlbumMutation();

  const navigate = useNavigate();

  const goToSet = (set_id: string) => {
    navigate(`/account/?tab=editSet&set=${set_id}`);
  };

  useEffect(() => {
    search({ filters: [], limit_by_file_set: true })
      .unwrap()
      .then((result) => {
        setFileSets(result);
      })
      .catch((response) => handleServerResponse(response));
  }, []);

  const onAddPhotos = (files: File[]) => {
    createFileSet({})
      .unwrap()
      .then((result) => {
        goToSet(result.id);
        addPhotosToSet({ set_id: result.id, files: files }).catch((response) =>
          handleServerResponse(response),
        );
      })
      .catch((response) => handleServerResponse(response));
  };

  if (isSetCreationLoading) {
    return (
      <Card
        bodyClasses={clsx("flex gap-8 flex-col w-full justify-center p-10")}
      >
        <Loader text={"Создание нового сета"} />
      </Card>
    );
  }


  return (
    <Card bodyClasses={clsx("flex gap-8 flex-col w-full justify-center p-10")}>
      <div className="flex w-full gap-4">
        <div className={clsx("flex w-full items-center")}>
          <Dropzone
            maxSize={MEDIA_MAX_SIZE}
            maxFiles={200}
            selectedFiles={[]}
            onChange={onAddPhotos}
            placeholder={"Создать новый сет"}
            acceptedTypes={{
              "image/*": PHOTO_ACCEPTED_FORMATS,
              "video/*": VIDEO_ACCEPTED_FORMATS,
            }}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        {isLoading ? (
          <div className="flex w-full justify-center">
            <Loader />
          </div>
        ) : (
          fileSets.map((set, index) => (

              <CardWithPhoto key={index}
                             id={set.files[0].objs[0].id} onClick={() => goToSet(set.id)}
              >

                <Typography pattern="body1" additionalClasses="!text-[18px]" hasTruncate={false}>
                  {set.title?.split('//')[langId] || "<Без названия>"}
                </Typography>
                <Typography pattern="body1" additionalClasses="!text-[14px] text-text-gray pb-1"
                            italic={true}>
                  {set.files.length} фотографий
                </Typography>
              </CardWithPhoto>

          ))
        )}
      </div>
    </Card>
  );
};
