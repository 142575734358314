import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  Modal as NextModal,
} from "@nextui-org/react";
import { Typography } from "@mui/material";
import { CloseIcon } from "@/shared/icons";

type TScrollBehavior = "inside" | "normal" | "outside";
type TModalSizes =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "full";

type Props = {
  open: boolean;
  title?: string;
  size?: TModalSizes;
  onClose: () => void;
  setActive: Dispatch<SetStateAction<boolean>>;
};
export const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  title,
  open,
  size = "3xl",
  onClose,
  setActive,
}) => {
  const isTitle = Boolean(title);
  const defaultClasses =
    "flex h-16 w-full items-center justify-between px-8 pt-4";
  const additionalClasses = "border-b border-solid border-stroke-default";

  const classes = isTitle
    ? defaultClasses
    : `${defaultClasses} ${additionalClasses}`;

  const baseClasses = `gap-8 rounded-lg flex flex-col bg-bg-white `;

  const handleCloseClick = async () => {
    onClose && (await onClose());
    await setActive(false);
  };

  return (
    <NextModal
      isOpen={open}
      size={size}
      onClose={handleCloseClick}
      classNames={{
        base: "rounded-lg",
        backdrop: "bg-backdrop-bg backdrop-blur-modal",
      }}
      scrollBehavior="inside"
      hideCloseButton
      shouldBlockScroll
    >
      <ModalContent>
        <ModalHeader className="flex w-full justify-between gap-1">
          {title ? <Typography>{title}</Typography> : <div></div>}

          <CloseIcon onClick={handleCloseClick} />
        </ModalHeader>
        <ModalBody className="p-0">{children}</ModalBody>
      </ModalContent>
    </NextModal>
  );
};
