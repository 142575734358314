import React from "react";

import { IconContainer } from "../ui/iconContainer";
import { TIconProps } from "../ui/type";

export const RetryIcon = (props: TIconProps) => (
  <IconContainer size="16" {...props}>
    <path d="M14 8C14.0001 9.57743 13.3791 11.0915 12.2712 12.2144C11.1634 13.3373 9.65791 13.9788 8.08062 14H8C6.46765 14.0038 4.99268 13.4174 3.88125 12.3625C3.83352 12.3174 3.79515 12.2633 3.76833 12.2033C3.74152 12.1433 3.72677 12.0787 3.72494 12.013C3.72311 11.9473 3.73424 11.8819 3.75768 11.8206C3.78112 11.7592 3.81642 11.703 3.86156 11.6553C3.9067 11.6076 3.9608 11.5692 4.02077 11.5424C4.08074 11.5156 4.14541 11.5008 4.21108 11.499C4.27675 11.4972 4.34213 11.5083 4.4035 11.5317C4.46487 11.5552 4.52102 11.5905 4.56875 11.6356C5.28362 12.3098 6.1813 12.7584 7.14964 12.9254C8.11799 13.0923 9.11406 12.9703 10.0134 12.5744C10.9128 12.1786 11.6756 11.5265 12.2065 10.6997C12.7375 9.87282 13.013 8.90786 12.9987 7.92534C12.9844 6.94281 12.6809 5.98627 12.1262 5.17522C11.5714 4.36418 10.79 3.73457 9.87944 3.36506C8.96893 2.99555 7.96973 2.90252 7.00665 3.09759C6.04358 3.29266 5.15933 3.76718 4.46438 4.46187C4.45927 4.4674 4.45384 4.47261 4.44812 4.4775L2.78688 6H4.5C4.63261 6 4.75979 6.05268 4.85355 6.14644C4.94732 6.24021 5 6.36739 5 6.5C5 6.63261 4.94732 6.75978 4.85355 6.85355C4.75979 6.94732 4.63261 7 4.5 7H1.5C1.36739 7 1.24021 6.94732 1.14645 6.85355C1.05268 6.75978 1 6.63261 1 6.5V3.5C1 3.36739 1.05268 3.24021 1.14645 3.14644C1.24021 3.05268 1.36739 3 1.5 3C1.63261 3 1.75979 3.05268 1.85355 3.14644C1.94732 3.24021 2 3.36739 2 3.5V5.3625L3.76562 3.75C4.60561 2.91327 5.67467 2.34416 6.83783 2.11452C8.001 1.88487 9.20612 2.00498 10.3011 2.45968C11.396 2.91438 12.3317 3.68329 12.9901 4.66934C13.6484 5.65539 13.9998 6.81438 14 8Z" />
  </IconContainer>
);
