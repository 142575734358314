import { SETTINGS_CONTAINER_WIDTH } from "@/shared/constants/elementsWidth";
import { Divider, Typography, successNotify } from "@/shared/ui-kit";
import { Image } from "@nextui-org/react";
import clsx from "clsx";
import React from "react";
import { InfoEditor } from "../InfoEditor";
import { Assets, IFileResponse } from "@/entities/assets/types";
import { useGetPhoto } from "@/entities/assets/hooks/useGetPhoto";
import { DeleteIcon } from "@/shared/icons/32px";
import {
  useGetEditableFileInfoQuery,
  useUpdateOneFileMutation,
} from "@/entities/assets/api/apiAssetsSlice";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";

type Props = {
  selectedPhoto: IFileResponse;
  deletePhoto: (photoId: string) => void;
};
export const SelectedPhotoBlock = ({ selectedPhoto, deletePhoto }: Props) => {
  let objId = selectedPhoto?.objs[0].id

  for (let i = 0; i < selectedPhoto?.objs.length; i++) {
    if (selectedPhoto?.objs[i].size === "normal") {
      objId = selectedPhoto?.objs[i].id;
      break
    }
  }

  const { imageUrl } = useGetPhoto(objId.toString());
  const { data: photoInputsData } = useGetEditableFileInfoQuery();
  const [updateOneFile] = useUpdateOneFileMutation();

  const updateFileInfo = ({
    id,
    data,
  }: {
    id: string;
    data: Assets.IEditableFileInfo;
  }) => {
    updateOneFile({ id: id, ...data })
      .unwrap()
      .then(() => {
        successNotify({
          title: "Успешно изменено",
        });
      })
      .catch((response) => handleServerResponse(response));
  };
  return (
    <>
      <Divider />
      <div className="relative mx-auto w-fit max-w-[900px] object-cover">
        <Image
          className={clsx(
            "aspect-3/2",
            SETTINGS_CONTAINER_WIDTH,
          )}
          style={{objectFit: 'contain', background: '#727272'}}
          src={imageUrl}
          alt="photo"
        />
        <div
          className="absolute right-0 top-0 z-20 rounded-sm bg-bg-white p-1"
          onClick={() => deletePhoto(selectedPhoto.id)}
        >
          <DeleteIcon />
        </div>
      </div>

      <div className="flex w-full flex-col gap-6">
        <Typography textAlign="center" additionalClasses="text-main !text-lg">
          Edit photo info
        </Typography>
        <InfoEditor
          sendData={updateFileInfo}
          data={photoInputsData}
          defaultData={selectedPhoto}
          buttonWidth={"max-w-[400px]"}
        />
      </div>
    </>
  );
};
