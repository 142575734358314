import { useEffect, useState } from "react";
import { useGetFileObjQuery } from "../api/apiAssetsSlice";

export function useGetPhoto(id?: string) {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const {
    data: imageData,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useGetFileObjQuery(
    {
      id,
    },
    { skip: !id },
  );
  useEffect(() => {
    if (imageData) {
      const url = URL.createObjectURL(imageData);
      setImageUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [imageData]);

  return { imageUrl, isLoading, isSuccess, isError, refetch };
}
