import React from "react";
import Providers from "./providers/Provider";
import { Pages } from "./pages/Pages";
import { Notify } from "./shared/ui-kit";
import { NextUIProvider } from "@nextui-org/react";

function App() {
  return (
    <Providers>
      <NextUIProvider>
        <Pages />
      </NextUIProvider>
      <Notify />
    </Providers>
  );
}

export default App;
