import React from "react";
import { IconContainer } from "./ui/iconContainer";
import { TIconProps } from "./ui/type";

export const CloseIcon = (props: TIconProps) => {
  return (
    <IconContainer {...props}>
      <g clipPath="url(#clip0_1359_130007)">
        <path
          d="M18 6L6 18"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1359_130007">
          <rect width="24" height="24" className="fill-bg-white" />
        </clipPath>
      </defs>
    </IconContainer>
  );
};
