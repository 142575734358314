import React from "react";
import { IconContainer } from "./ui/iconContainer";
import { TIconProps } from "./ui/type";

export const SearchIcon = (props: TIconProps) => (
  <IconContainer size="37" {...props}>
    <path
      d="M37 32.9132L27.5028 23.4143C29.1737 20.9384 30.0652 18.019 30.0625 15.0321C30.0625 6.74409 23.3218 0 15.0321 0C6.74409 0 0 6.74409 0 15.0321C0 23.3218 6.74409 30.0642 15.0321 30.0642C18.0192 30.0677 20.9388 29.1761 23.4143 27.5045L32.9098 37L37 32.9132ZM15.0321 25.4375C12.2728 25.4348 9.62725 24.3375 7.67612 22.3864C5.72499 20.4353 4.62767 17.7897 4.625 15.0304C4.62812 12.2712 5.72557 9.62596 7.67661 7.67493C9.62764 5.72389 12.2729 4.62643 15.0321 4.62332C17.7913 4.62643 20.4365 5.72389 22.3876 7.67493C24.3386 9.62596 25.4361 12.2712 25.4392 15.0304C25.4361 17.7896 24.3386 20.4349 22.3876 22.3859C20.4365 24.3369 17.7913 25.4344 15.0321 25.4375Z"
      fill="#AE90FE"
    />
  </IconContainer>
);
