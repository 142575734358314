import {
  useLoginMutation,
  useSignupMutation,
} from "@/entities/users/api/apiUsersSlice";
import { Users } from "@/entities/users/types";
import { QRCodeForm } from "@/features/SignUpForms/QRCodeForm";
import { SignUpForm } from "@/features/SignUpForms/SignUpForm";
import { CONTAINER_WIDTH } from "@/shared/constants/elementsWidth";
import { Card, Divider, Loader, QRCode, Typography } from "@/shared/ui-kit";
import { handleServerResponse } from "@/shared/utils/handleServerResponse";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [QRCodeValue, setQRCodeValue] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [totp, setTotp] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const navigate = useNavigate();

  const [login] = useLoginMutation();
  const [signup, isLoading] = useSignupMutation();

  const getTotp = (value: string) => {
    setTotp(value);
    setCurrentTab(3);

    login({ email, password, totp: value })
      .unwrap()
      .then((response) => {
        localStorage.setItem("refresh_token", response.refresh_token);
        localStorage.setItem("access_token", response.access_token);
        navigate("/account");
      })
      .catch((response) => {
        setCurrentTab(2);
        handleServerResponse(response);
      });
  };
  const signUp = ({
    email,
    invitation,
    password,
    username,
    full_name,
  }: Users.ISignUp) => {
    signup({ email, invitation, password, username, full_name })
      .unwrap()
      .then((response) => {
        const totp = response.data.totp_url;
        setEmail(email);
        setPassword(password);

        setQRCodeValue(totp);
        setCurrentTab(2);
      })
      .catch((response) => handleServerResponse(response));
  };
  return (
    <div className="flex h-[calc(100vh-90px)] w-[100vw] items-center justify-center">
      <div className={CONTAINER_WIDTH}>
        <div className="mx-auto w-[500px]">
          <Card bodyClasses="flex  flex-col gap-6">
            <Typography className="text-center text-[30px]">
              Регистрация
            </Typography>
            <Divider />
            {currentTab === 1 ? (
              <SignUpForm getData={signUp} />
            ) : currentTab === 2 ? (
              <QRCodeForm QRCodeValue={QRCodeValue} getTotp={getTotp} />
            ) : (
              <Card>
                <Loader />
              </Card>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
