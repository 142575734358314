import { Checkbox as NextCheckbox, CheckboxProps } from "@nextui-org/react";
import clsx from "clsx";
import React, { FC, PropsWithChildren } from "react";

import { CheckMark } from "@/shared/icons";

export interface ICheckbox extends CheckboxProps {
  checked: boolean;
  isHover?: boolean;
}

export const Checkbox: FC<PropsWithChildren<ICheckbox>> = ({
  children,
  checked,
  isHover,
  disabled,
  ...props
}) => {
  const hoverClasses =
    "group-data-[hover=true]:before:bg-bg-lilaclight left-0 group-data-[hover=true]:before:!border-stroke-hover";
  const focusClasses =
    " group-data-[focus-visible=true]:before:border-stroke-hover";

  const disabledClasses =
    "before:bg-button-primary-bg-disabled pointer-events-none";
  const checkClasses = "group-data-[selected=true]:before:bg-main";

  const classes = disabled
    ? disabledClasses
    : isHover
      ? `before:border before:border-solid before:border-stroke-hover ${checkClasses} before:bg-bg-lilac`
      : `before:border before:border-solid before:border-stroke-default before:bg-bg-white ${checkClasses} ${focusClasses} ${hoverClasses}`;
  return (
    <NextCheckbox
      id={props.id}
      radius="sm"
      isSelected={checked}
      color="secondary"
      classNames={{
        base: clsx(hoverClasses, "flex items-center", {
          [disabledClasses]: disabled,
        }),
        wrapper: `${classes}`,
        icon: clsx("w-full h-full p-1", {
          "bg-button-primary-bg-disabled stroke-button-primary-text-disabled":
            disabled,
          "bg-main ": !disabled,
        }),
      }}
      {...props}
      size="lg"
    >
      {children}
    </NextCheckbox>
  );
};
