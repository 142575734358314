import React, { useState } from "react";
import { IFileResponse } from "@/entities/assets/types";
import { Image, Spinner } from "@nextui-org/react";
import { DeleteIcon, RetryIcon } from "@/shared/icons/16px";
import { useGetPhoto } from "@/entities/assets/hooks/useGetPhoto";
import { PhotoLoading } from "./ui/photoLoading";

type Props = {
  photo: IFileResponse;
  selectPhoto: (photo: any) => void;
  deletePhoto: (photo: any) => void;
};
export const PhotoScrollItem = ({ photo, selectPhoto, deletePhoto }: Props) => {
  const [isDeleteLoading, setIsDeleteLoading] = useState<null | string>(null);

    let objId = photo.objs[0].id

    for (let i = 0; i < photo.objs.length; i++) {
        if (photo.objs[i].size == "small") {
            objId = photo.objs[i].id;
            break
        }
    }

  const { imageUrl, isLoading, refetch } = useGetPhoto(
      objId.toString(),
  );

  const loading = isLoading || isDeleteLoading === photo.id;
  return (
    <div className="relative">
      {loading ? (
        <PhotoLoading />
      ) : imageUrl ? (
        <Image
          onClick={() => selectPhoto(photo)}
          width={130}
          classNames={{
            wrapper: " max-h-full h-full ",
            zoomedWrapper: "max-h-full h-full",
            img: "object-cover h-full shrink-0 min-w-[130px] cursor-pointer",
          }}
          className='aspect-3/2 bg-cover bg-main'
          style={{objectFit: 'contain'}}
          isZoomed
          src={imageUrl}
          alt={"photo"}
        />
      ) : (
        <div className="flex h-[100px] w-[130px] flex-col items-center justify-center gap-2 text-red-500">
          Ошибка
          <div onClick={() => refetch()}>
            <RetryIcon rotate="90" pointer className="!stroke-stroke-error" />
          </div>
        </div>
      )}
      <div
        className="absolute right-0 top-0 z-20 rounded-sm bg-bg-white p-1"
        onClick={() => {
          if (!loading) {
            setIsDeleteLoading(photo.id);
            deletePhoto(photo.id);
          }
        }}
      >
        <DeleteIcon disabled={loading} />
      </div>
    </div>
  );
};
