import { BACKEND_ENDPOINTS } from "@/shared/endpoints";

async function getAccessToken() {
  try {
    const token = localStorage.getItem("refresh_token");
    if (!token) {
      return false;
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${BACKEND_ENDPOINTS.refresh()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return false;
    }
    const result = await response.json();
    if (result.access_token) {
      localStorage.setItem("access_token", result.access_token);
      return true;
    }
  } catch (error) {
    return false;
  }
}

export default getAccessToken;
