export enum FontWeightDesktop {
  headline1 = "xl:font-bold", //700
  body1 = "xl:font-normal", //400,
  button = "xl:font-normal", //400,
}

export enum FontWeightTablet {
  headline1 = "md:font-bold", //700
  body1 = "md:font-normal", //400,
  button = "md:font-normal", //400,
}

export enum FontWeightMobile {
  headline1 = "font-bold", //700
  body1 = "font-normal", //400,
  button = "font-normal", //400,
}

export enum FontSizeDesktop {
  headline1 = "xl:text-xl", //20
  body1 = "xl:text-base", //16
  button = "xl:text-xl", //20
}

export enum FontSizeTablet {
  headline1 = "md:text-xl", //20
  body1 = "md:text-base", //16
  button = "md:text-base", //16
}

export enum FontSizeMobile {
  headline1 = "text-base", //16
  body1 = "text-base", //16
  button = "text-base", //16
}

export enum LineHeightDesktop {
  headline1 = "xl:leading-7", //28
  body1 = "xl:leading-6", //24
  button = "xl:leading-6", //24
}

export enum LineHeightTablet {
  headline1 = "md:leading-6", //24
  body1 = "md:leading-6", //24
  button = "md:leading-6", //24
}

export enum LineHeightMobile {
  headline1 = "leading-6", //24
  body1 = "leading-6", //24
  button = "leading-6", //24
}

export const TextAlign = {
  start: "text-start",
  end: "text-end",
  center: "text-center",
  left: "text-left",
  right: "text-right",
  justify: "text-justify",
};
