import React from "react";

import { IconContainer } from "../ui/iconContainer";
import { TIconProps } from "../ui/type";

export const DeleteIcon = (props: TIconProps) => (
  <IconContainer size="32" {...props}>
    <path
      d="M28 8.40073H4M11.3333 8.39979L11.6267 6.65313C11.84 5.38646 12 4.43979 14.2533 4.43979H17.7467C20 4.43979 20.1733 5.43979 20.3733 6.66646L20.6667 8.39979M25.1339 12.1875L24.2672 25.6142C24.1205 27.7075 24.0005 29.3342 20.2805 29.3342H11.7205C8.00052 29.3342 7.88052 27.7075 7.73385 25.6142L6.86719 12.1875M13.7733 22H18.2133M12.6667 16.6667H19.3333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconContainer>
);
