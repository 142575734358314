import React from "react";
import { IconContainer } from "../ui/iconContainer";
import { TIconProps } from "../ui/type";

export const DeleteIcon = (props: TIconProps) => {
  return (
    <IconContainer size="16" {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 4.20036H2M5.66667 4.1999L5.81333 3.32656C5.92 2.69323 6 2.2199 7.12667 2.2199H8.87333C10 2.2199 10.0867 2.7199 10.1867 3.33323L10.3333 4.1999M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375M6.88667 11H9.10667M6.33333 8.33333H9.66667"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconContainer>
  );
};
