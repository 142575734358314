import {
  Accordion,
  Autocomplete,
  Button,
  InputByType,
  Loader,
} from "@/shared/ui-kit";
import React, { FormEvent, useEffect } from "react";
import {
  Controller,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { Assets, IFileResponse } from "@/entities/assets/types";
import clsx from "clsx";

type Props = {
  data: Assets.IEditableFileInfo | undefined;
  defaultData?: IFileResponse;
  buttonWidth?: string;
  isLoading?: boolean;
  sendData: ({
    id,
    data,
  }: {
    id?: string;
    data: Assets.IEditableFileInfo;
  }) => void;
};
export const InfoEditor = ({
  data,
  defaultData,
  isLoading,
  buttonWidth,
  sendData,
}: Props) => {
  const metaInfo = data?.meta;

  const form: UseFormReturn<Partial<any>, UseFormProps> = useForm<Partial<any>>(
    {
      mode: "onSubmit",
      reValidateMode: "onSubmit",
    },
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (data && defaultData) {
      goToDefaultValues();
    }
  }, [defaultData, data]);

  const goToDefaultValues = () => {
    Object.keys(defaultData)?.map((item) => {
      setValue(item, defaultData[item]?.toString());
    });
    if (defaultData.meta) {
      Object.keys(defaultData.meta)?.map((item) => {
        setValue(item, defaultData.meta[item]?.toString());
      });
    }
  };

  const trimString = (name: string) => {
    // @ts-ignore
    setValue(name, getValues(name)?.trim());
  };

  const saveChanges = () => {
    let newFields = { meta: {} };
    getEditableFields(data).map((field) => {
      newFields[field] = getValues(field) || null;
    });
    getEditableFields(metaInfo).map((field) => {
      newFields.meta[field] = getValues(field) || null;
    });

    const newData = { ...newFields };
    const id = defaultData.id;

    // @ts-ignore
    sendData({ id: id, data: newData });
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    await handleSubmit(saveChanges)(e);
  };

  const getEditableFields = (data: Assets.IEditableFileInfo) => {
    return data
      ? Object.keys(data)?.filter((item) => !!data[item].editable)
      : [];
  };
  const renderEditableFields = (data: Assets.IEditableFileInfo) => {
    if (!data) return null;

    const editableFields = getEditableFields(data);
    return (
      <>
        {editableFields?.map((key, index) => {
          const name = data[key].title;
          const type = data[key].accept_type;
          if (name && type !== "enum") {
            return (
              <div key={index} className='w-full max-w-[300px]'>
                <Controller
                  control={control}
                  name={key}
                  render={({ field: { onChange, value } }) => (
                    <InputByType
                      type={type}
                      id={key}
                      label={name}
                      height="h-[30px]"
                      value={value}
                      onBlur={() => trimString(name)}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            );
          }

          if (name && type === "enum") {
            const list = Array.isArray(data[key].values)
              ? data[key].values.map((enumItem) => ({
                  value: enumItem.value,
                  label: enumItem.title,
                }))
              : [];
            return (
              <div key={index} className="w-full max-w-[300px]">
                <Controller
                  control={control}
                  name={key}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      key={index}
                      list={list}
                      isClearable={false}
                      label={name}
                      selectedKey={value}
                      onSelectionChange={onChange}
                    />
                  )}
                />
              </div>
            );
          }
        })}
      </>
    );
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <FormProvider {...form}>
      <form
        className={clsx("mx-auto flex w-full flex-col items-center gap-8 pb-3")}
        onSubmit={onSubmit}
      >
        <div className="flex w-full flex-wrap justify-center gap-4">
          {renderEditableFields(data)}
        </div>
        {metaInfo && (
          <Accordion
            header="Meta"
            // @ts-ignore
          >
            <div className="flex flex-wrap justify-center gap-4 pb-8 pt-4">
              {renderEditableFields(metaInfo)}
            </div>
          </Accordion>
        )}
        <Button width={buttonWidth} type="submit">
          Применить изменения
        </Button>
      </form>
    </FormProvider>
  );
};
