import React, { FC, PropsWithChildren } from "react";
import { LinkProps, Link as NextLink } from "@nextui-org/react";

type Props = {
  href: string;
} & LinkProps;
export const Link: FC<PropsWithChildren<Props>> = ({
  href,
  children,
  ...props
}) => {
  return (
    <NextLink color="secondary" href={href} {...props}>
      {children}
    </NextLink>
  );
};
